<template>
<div class="regex-item">
  <h3 class="d-inline-block">{{ name ? name : 'unknown regex'}}</h3>
  <div v-if="!edit" @click="edit = true" class="settings-button d-inline-block ml-2 pt-1 pb-1"><icon type="edit" /></div><br />

  <div class="lighter mb-1">(Always use regexes without the "/" slashes)</div>
  <div v-if="edit">
    <label class="vform-label white mb-0">Regex</label><br />
    <input placeholder="name" class="form-text form-text-dark mt-2" type="text" v-model="name" />
    <input placeholder="regex" class="form-text form-text-dark mt-2"  type="text" v-model="content" /><br />
    <label class="vform-label white">Contexts</label><br />
    <context-editor
        v-if="regexItem"
        ref="contextEditor"
        :asset-id="regexItem.id"
        :meta-values="regexItem.metaValues ? regexItem.metaValues : []"
        :edit-mode="true"
        class="w-50"
    /><br />
    <div @click="createRegex" class="settings-button d-inline-block">{{ $t('save') }}</div>
    <div @click="cancel" class="settings-button d-inline-block ml-1">{{ $t('abort') }}</div>
    <div v-if="regexItem && regexItem.id" @click="deleteRegex" class="settings-button d-inline-block ml-1">{{ $t('delete') }}</div>
  </div>
  <div v-else>
    <label class="vform-label white mb-0">Regex</label>
    <div class="mb-2">{{ content }}</div>
    <label class="vform-label white mt-2 mb-0">Contexts</label>
    <context-editor
        v-if="regexItem"
        ref="contextEditor"
        :asset-id="regexItem.id"
        :meta-values="regexItem.metaValues ? regexItem.metaValues : []"
    />
    <h4 class="mt-4 mb-0 uppercase">Test</h4>
    <div class="lighter">Test your regex with an example of your choice</div>
    <input v-model="testString" class="form-text form-text-dark mt-2" type="text" placeholder="Test string" />
    <div v-if="testString" class="form-error p-1 mt-1">
      <h4>Results</h4>
      <template v-if="testResult && testResult.groups">
        <div :key="res" v-for="res in getAndSortKeys(testResult.groups)" class="mb-1">
          {{ res }}: {{ testResult.groups[res] }}
        </div>
        <div class="mt-3">
          <b>Resulting string used for comparison:</b><br />
          <span :key="res" v-for="res in getAndSortKeys(testResult.groups)" class="mb-1">{{ testResult.groups[res] }}</span>
        </div>
      </template>
      <div v-else>
        No match
      </div>
    </div>
  </div>
</div>
</template>

<script>
import {AssetTypes} from "@/enum";
import ContextEditor from "@/components/ContextEditor.vue";
import regex from "@/views/Regex.vue";
import Icon from "@/components/Icon.vue";
export default {
  name: "RegexItem",
  components: {ContextEditor, Icon},
  props: {
    edit: {type: Boolean, default: false},
    organizationId: {type: String, required: true},
    teams: {type: Array, required: true},
    regexItem: {type: Object, default: () => {return {}}}
  },
  data() {
    return {
      name: '',
      content: '',
      testString: '',
    };
  },
  computed: {
    regex() {
      return regex
    },
    testResult() {
      const string = this.testString;
      if(this.regexItem && this.regexItem.content) {
        const regex = new RegExp(this.regexItem.content, "");
        let match = string.match(regex);
        if(match) {
          console.log('match', match)
        }
        return match;
      }
      return false;
    }
  },
  beforeMount() {
    this.setLocalItems();
  },
  methods: {
    getAndSortKeys(obj) {
      // todo: make this alphabetically
      return Object.keys(obj).sort((a, b) => {
        return a.localeCompare(b);
      });
    },
    setLocalItems() {
      if(this.regexItem && Object.keys(this.regexItem).length) {
        const keys = Object.keys(this.regexItem);
        try {
          this.regexItem.content = JSON.parse(this.regexItem.content)
        } catch {
          console.log('could not jsonparse regex')
        }
        for(let i = 0; i < keys.length; i++) {
          const key = keys[i];
          this[key] = this.regexItem[key];
        }
      }
    },
    async deleteRegex() {
      if(this.regexItem.id) {
        await this.$store.dispatch('deleteRegexs',
          [this.regexItem.id]
        )
      }
      this.$emit('deleted')
    },
    cancel() {
      this.edit = false;
      this.$emit('cancel');
    },
    async createRegex() {
      if(this.regexItem.id) {
        await this.$store.dispatch('updateRegex', {
          id: this.regexItem.id,
          name: this.name,
          content: JSON.stringify(this.content),
          teams: this.teams,
        })
        await this.$refs.contextEditor.saveContextValue();
      } else {
        await this.$store.dispatch('createRegex', {
          name: this.name,
          content: JSON.stringify(this.content),
          organizationId: this.organizationId,
          teams: this.teams,
          type: AssetTypes.REGEX
        })
      }
      // todo: if context editor has any changes: save the metaValues as well
      this.$emit('saved')
    }
  }
}
</script>

<style scoped lang="scss">
.regex-item {
  input {
    max-width: 500px;
  }
}
</style>