<template>
  <div
      class="form-section col-12"
  >
    <label class="lighter mb-0">4 {{ $t("Regex") }}</label><br/>
    <div class="bg-darker p-2 border-radius mt-1 mb-1">If you use multiple regexes, the algorithm will stop at the first matching regex. So sort them by using the most specific first and the most general last.</div>
    <div class="form-item">
      Select a regex if your data matches e.g. versions numbers etc
      <universal-selector
          class="mt-1"
          store-name="Regex"
          list-name="importRegex"
          @addItem="addRegex"
          :exclude-from-item-list="regexes"
      />
      <div v-if="regexes"
           v-for="regex in regexes"
           :key="regex.name"
      >
        <tag
            :is-removable="true"
            :tag="regex"
            @removeTag="removeRegex"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Tag from "@/components/Tag.vue";
import UniversalSelector from "@/components/organizations/UniversalSelector.vue";
export default {
  name: "RegexSelector" ,
  components: {UniversalSelector, Tag},
  data() {
    return {
      regexes: [],
      mode: 'keepItem'
    }
  },
  methods: {
    addRegex(regex) {
      this.regexes.push(regex);
      this.$emit('setRegexes', this.regexes);
    },
    removeRegex(item) {
      const {id} = item;
      const index = this.regexes.findIndex(el => {
        return el.id === id;
      })
      if(index !== -1) {
        this.regexes.splice(index, 1);
      }
      this.$emit('setRegexes', this.regexes);
    },
  }
}
</script>

<style scoped lang="scss">
.mode-selection b {
  font-family: 'Inter bold', sans-serif;
}
.bg-darker {
  background-color: var(--vform-editor-ui-secondary-color);
}
</style>