<template>
  <full-width-sidebar-left
      sub-menu-selector="organizingpanel"
  >
    <div slot="mainContent">
      <h1>
        <icon class="mr-2" size="0.9" type="code"/>
        {{ $t('Regex') }}
      </h1>
      <div @click="loadRegex">Load</div>
      <organization-switcher
          :show-all-orgs="true"
          :show-teams="true"
          @setOrganization="id => organizationId = id"
          @setTeam="id => {teamId = id}"
      />
      <div class="lighter">Please use only the global team for now</div>
      <example-regex class="mt-3" />
      <div v-if="organizationId && teamId && !addRegex" class="settings-button d-inline-block mt-2" @click="addRegex = true">
        add new regex
        <icon class="ml-1" type="square-plus" />
      </div>
      <regex-item
          v-if="addRegex"
          class="mt-3 p-3 bg-beige"
          :edit="true"
          :organization-id="organizationId"
          :teams="[teamId]"
          @saved="loadRegex"
          @cancel="addRegex = false"
          @deleted="loadRegex"
      />
      <regex-item
          class="mt-2 p-3 bg-dark border-radius regex-item"
          v-for="regex in $store.getters.getRegexsByListName(listName)"
          :key="regex.id + forceReRenderKey"
          :organization-id="regex.organizationId"
          :teams="regex.teams"
          :regex-item="regex"
          @saved="loadRegex"
          @deleted="loadRegex"
      />
    </div>
  </full-width-sidebar-left>
</template>

<script>
import FullWidthSidebarLeft from "../layouts/FullWidthSidebarLeft";
import OrganizationSwitcher from "../components/organizations/OrganizationSwitcher";
import Icon from "../components/Icon";
import RegexItem from "@/components/regex/RegexItem.vue";
import ExampleRegex from "@/components/regex/ExampleRegex.vue";
export default {
  name: 'Regex',
  components: {
    FullWidthSidebarLeft,
    OrganizationSwitcher,
    Icon,
    RegexItem,
    ExampleRegex
  },
  data() {
    return {
      id: '',
      organizationId: '',
      teamId: '',
      listName: 'regexes',
      addRegex: false,
      forceReRenderKey: 0
    };
  },
  watch: {
    organizationId(val) {
      if(val) {
        this.loadRegex();
      }
    },
    teamId(val) {
      if(val) {
        this.loadRegex();
      }
    }
  },
  beforeMount() {

  },
  methods: {
    async loadRegex() {
      console.log('loading:.')
      this.addRegex = false;
      await this.$store.dispatch('loadRegexs', {
        listName: this.listName,
        add: {
          filter: {
            [this.listName]: 'organizationId eq ' + this.organizationId,
          },
        },
        keep: {
          teams: this.teamId ? [this.teamId] : [],
          include: ['metaValues']
        }
      }).then(() => {
        this.forceReRenderKey++;
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.regex-item {
  background-color: var(--vform-editor-ui-secondary-color);
}
</style>