<template>
  <info-panel
    :preview-id="id"
    :collapsible="collapsible"
    :info-panel-header="$t('allowTeamAccess')"
    :initially-collapsed="initiallyCollapsed"
    v-if="showAsInfoPanel"
  >
    <div slot="info">
      <tag
          :name="team.displayName"
          :is-removable="!readOnly"
          icon-left="users"
          @removeTag="removeTeam"
          v-for="(team, index) in teams"
          :key="index"
          :tag="team"
          :organization-id="team.organizationId"
      />
      <universal-selector
          v-if="!readOnly"
          class="mt-2"
          @addItem="addTeam"
          :exclude-from-item-list="teams"
          list-name="userTeamList"
          store-name="Team"
          filter-attribute="displayName"
          :limit-by-organization-id="limitByOrganizationId"
          tag-icon="users"
          :show-organization="true"
          :after-query-filter="function (item) {
            return visibilities.includes(item.visibility) || item.ownerId === $store.getters.getCurrentUserId
          }"
      />
      <checkbox-widget
          class="mt-2"
          :initial-value="visibilities.includes(0)"
          :label-text="$t('showPrivateTeams')"
          @change="toggleVisibility"
      />
      <div class="w-100" />
      <child-resources
        :target-id="id"
        :store-name="storeName"
      />
    </div>
  </info-panel>
  <div v-else>
    <tag
        :name="team.displayName"
        :is-removable="true"
        icon-left="users"
        @removeTag="removeTeam"
        v-for="(team, index) in teams"
        :key="index"
        :tag="team"
        :organization-id="team.organizationId"
    />
    <universal-selector
        class="mt-2"
        @addItem="addTeam"
        :exclude-from-item-list="teams"
        list-name="userTeamList"
        store-name="Team"
        filter-attribute="displayName"
        :limit-by-organization-id="limitByOrganizationId"
        tag-icon="users"
        :show-organization="true"
        :after-query-filter="function (item) {return visibilities.includes(item.visibility) || item.ownerId === $store.getters.getCurrentUserId}"
    />
    <checkbox-widget
        class="mt-2"
        :initial-value="visibilities.includes(0)"
        :label-text="$t('showPrivateTeams')"
        @change="toggleVisibility"
    />
    <div class="w-100" />
    <child-resources
        :target-id="id"
        :store-name="storeName"
    />
  </div>
</template>

<script>
import UniversalSelector from "@/components/organizations/UniversalSelector";
import Tag from "@/components/Tag";
import InfoPanel from "@/components/InfoPanel";
import ChildResources from "@/components/organizations/ChildResources";
import CheckboxWidget from "@/components/widgets/editors/Checkbox.vue";
export default {
  name: "TeamAttacher",
  components: {
    UniversalSelector,
    Tag,
    InfoPanel,
    ChildResources,
    CheckboxWidget
  },
  props: {
    teams: {type: Array, default: () => {return []}},
    id: {type: String, required: true},
    /**
     * @organizationId limit entries for a spezific organization
     * */
    limitByOrganizationId: {type: String, default: ''},
    storeName: {type: String, required: true},
    initiallyCollapsed: {type: Boolean, required: false, default: false},
    showAsInfoPanel: {type: Boolean, default: true},
    readOnly: {type: Boolean, default: false},
    collapsible: {type: Boolean, default: true}
  },
  data() {
    return {
      visibilities: [1, 2]
    }
  },
  methods: {
    toggleVisibility() {
      if(this.visibilities.includes(0)) {
        this.visibilities = [1, 2];
      } else {
        this.visibilities = [0, 1, 2];
      }
    },
    addTeam(team) {
      this.$store.dispatch(`clientAdd${this.storeName}Resource`, {
        id: this.id,
        params: [team.id],
      }).then(() => {
        this.$emit('added', team.id);
      })
    },
    removeTeam(team) {
      this.$store.dispatch(`clientRemove${this.storeName}Resource`, {
        id: this.id,
         params: [team.id],
      }).then(() => {
        this.$emit('removed', team.id);
      })
    },
  }
}
</script>