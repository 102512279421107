<template>
  <filter-section :id="id" :collapsible="collapsible">
    {{ limitToOrganizationIds }}
    <template slot="name">{{ $t('filter.teamFilter') }}</template>
    <template slot="content">
      <tag icon-left="users" v-for="(value, index) in tags" :key="index" :tag="value" @removeTag="removeTag"/>

      <universal-selector
          :after-query-filter="function (item) {
            return visibilities.includes(item.visibility) || item.ownerId === $store.getters.getCurrentUserId
          }"
          :exclude-from-item-list="tags"
          :show-organization="true"
          :limit-by-organization-ids="limitToOrganizationIds"
          class="mt-2"
          filter-attribute="displayName"
          list-name="TeamListinTeamfilter"
          store-name="Team"
          tag-icon="users"
          @addItem="addTeam"
      />
      <!--checkbox: if checked, visibilities = [0,1,2], else visiblities = [1,2]-->
      <checkbox-widget
          class="mt-2"
          :initial-value="visibilities.includes(0)"
          :label-text="$t('showPrivateTeams')"
          @change="toggleVisibility"
      />
    </template>
  </filter-section>
</template>

<script>
import Tag from "../../Tag";
import FilterSection from "./FilterSection";
import UniversalSelector from "../../organizations/UniversalSelector";
import CheckboxWidget from "@/components/widgets/editors/Checkbox.vue";

export default {
  name: "TagFilter",
  components: {
    CheckboxWidget,
    UniversalSelector,
    Tag,
    FilterSection,
  },
  props: {

    /**
     * An id in order to have a unique identifier when saving filter contents to storage
     * */
    id: {type: String, required: true},
    /**
     * If value changes, the textFilter clears its value
     * */
    clear: {type: Number, default: 0},
    collapsible: {type: Boolean, default: false},
    limitToOrganizationIds: {type: Array, default: () => { return []}}
  },
  data() {
    return {
      tags: [],
      visibilities: [1, 2],
    };
  },
  computed: {
    getId: function () {
      return this.$route.name + this.id + 'TextFilter';
    }
  },
  watch: {
    clear() {
      this.clearValue();
    },
  },
  beforeMount() {
    let tags = JSON.parse(window.localStorage.getItem(this.getId));
    if (tags) {
      this.tags = tags;
    }
  },
  mounted() {
    this.filter(true);
  },
  methods: {
    toggleVisibility() {
      if(this.visibilities.includes(0)) {
        this.visibilities = [1, 2];
      } else {
        this.visibilities = [0, 1, 2];
      }
    },
    setTags() {
      if(this.tags.length) {
        this.tags = this.tags.map(item => {
          const {displayName, name} = item;
          item.name = displayName ? displayName : name;
          return item;
        })
      }
    },
    clearValue() {
      this.tags = [];
      this.filter(true);
    },
    removeTag(tag) {
      this.tags = this.tags.filter(item => {
        return item.id !== tag.id
      });
      this.setTags();
      this.filter();
    },
    addTeam(tag) {
      this.tags.push(tag);
      this.setTags();
      this.filter();
    },
    filter(initial = false) {
      const filterParamId = this.getId;
      let params = {};

      if (this.tags && this.tags.length > 0) {
        let filterString = '';
        for (let i = 0; i < this.tags.length; i++) {
          filterString += this.tags[i].id;
          if (i !== this.tags.length - 1) {
            filterString += ',';
          }
        }

        params = {
          add: {
            teams: {
              [filterParamId]: filterString
            },
            default: {
              limit: 400,
            },
          }
        };
      } else {
        params = {
          remove: {
            teams: {filterParamId}
          }
        };
      }
      this.$emit('filter', params, initial, 'teams');
      window.localStorage.setItem(this.getId, JSON.stringify(this.tags));
    },
  },
}
</script>