<template>
  <div>
    <h3>Caching handler</h3>
    <div :class="{'cached': isCached, 'not-cached': !isCached}">
      Caching status: {{ isCached ? 'Cached' : 'Not Cached' }}
    </div>
    <div class="mb-1">{{ cachesNo}} queries cached</div>
    <button @click="generateCache" :disabled="isCached">Generate Cache</button>
    <button @click="deleteCache" :disabled="!isCached">Delete Cache</button>
    <button @click="checkCacheStatus">Reload Status</button>
    <div v-if="cacheHint" class="form-error p-2 mt-2">
      be aware that generating cache can take a while (sometimes up to 10 minutes)
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: "AssemblyCachingHandler",
  props: {
    projectId: {
      type: String,
      required: true,
    }
  },
  data() {
    return {
      cachesNo: 0,
      cacheHint: false
    };
  },
  computed: {
    isCached() {
      return parseInt(this.cachesNo) > 0;
    }
  },
  mounted() {
    this.checkCacheStatus();
  },
  methods: {
    ...mapActions(['clientGetAssemblyProjectInstancesCaching', 'clientTriggerAssemblyProjectInstancesCaching', 'clientDeleteAssemblyProjectInstancesCaching']),
    async checkCacheStatus() {
      console.log('checking cache status');
      const status = await this.clientGetAssemblyProjectInstancesCaching({projectIds: [this.projectId]});
      if(status && status.cacheStatuses) {
        const { cacheStatuses } = status;
        console.log('status', cacheStatuses[this.projectId]);
        const cacheStatus = parseInt(cacheStatuses[this.projectId]);
        this.cachesNo = cacheStatus;
      } else {
        this.cachesNo = 0;
      }
    },
    async generateCache() {
      this.cacheHint = true;
      await this.clientTriggerAssemblyProjectInstancesCaching({
        id: this.projectId,
        limit: 0,
        include: 'squashedMeta',
        filter: 'state in a h',
        fields: ['id', 'name'],
        sort: '+name'
      });
      await this.checkCacheStatus();
    },
    async deleteCache() {
      this.cacheHint = false;
      await this.clientDeleteAssemblyProjectInstancesCaching({ id: this.projectId });
      await this.checkCacheStatus();
    }
  }
};
</script>

<style scoped lang="scss">
.cached {
  color: #19e719;
}
.not-cached {
  color: grey;
}
button {
  background-color: var(--vform-editor-ui-secondary-color);
  color: #fff;
  border: 1px solid rgba(255,255,255,0.5);
  border-radius: 2px;
  margin-right: 5px;
  transition: all 300ms ease;
  &:hover {
    background-color: #333;
  }
}
button:disabled {
  background-color: grey;
  color: #ddd;
}
</style>