<template>
  <div class="col-12 instance-data-updater">
    <div>
      <div class="form-section col-12 pb-1 mb-1">
        <!--<info-helper class="float-right" text="UpdateMetaDataInfo"
        />-->
        <h3>{{ $t("UpdateHierarchy") }}</h3>
        <p class="lighter mb-1">{{ $t('instanceUpdaterHint') }}</p>
        <!-- Collapsible Section -->
      </div>
      <meta-data-upload
          ref="hierarchyUpdate"
          dropzone-id="hierarchyUpdate"
          class="mt-2 col-12"
          @metaDataColumns="setMetaDataColumns"
          @reset="reset"
          @fileReceived="fileReceived"
          @setVhubPreProcessor="setVhubPreProcessor"
          @partIdentifierColumn="(val) => {partIdentifierColumn = val}"
          :asset-id="$route.params.id"
      />
    <div v-if="metaDataColumns && metaDataColumns.length && partIdentifierColumn" class="col-12">
      <div class="row">
        <div class="col-12 col-md-12">
          <!--Data mapping...--->
          <div class="mt-4" v-if="metaDataColumns && metaDataColumns.length">
            <label class="lighter mb-0">3 {{ $t("mapData") }}</label> <info-helper class="ml-1" text="instanceMapInfo"/><br/>
            <item-mapper
                v-if="metaDataColumns.length"
                :items1="Object.values(updatableInstanceFields).map(item => {return item.key})"
                :items1-labels="Object.values(updatableInstanceFields).map(item => {return item.name})"
                :items2="metaDataColumns"
                :allow-multi-items-right="true"
                @update="(val) => {instanceMap = val}"
            />
          </div>
        </div>
      </div>
    </div>

    <regex-selector
        v-if="instanceMap && instanceMap[0] && instanceMap[0].source && instanceMap[0].targets.length && partIdentifierColumn"
        class="col-12"
        @setRegexes="setRegexes"
    />
    <div
        class="form-section col-12"
        v-if="instanceMap && instanceMap[0] && instanceMap[0].source && instanceMap[0].targets.length && partIdentifierColumn"
    >
      <label class="lighter mb-0">5 {{ $t("email") }}</label><br/>
      <input
          v-model="email"
          :class="['form-text form-text-dark w-100', $v.email.$dirty && $v.email.$error ? 'form-group--error' : '',]"
          type="text"
          @input="delayTouch($v.email)"
      />
      <div
          v-if="$v.email.$dirty && !$v.email.email"
          class="form-error mb-2"
      >
        {{ $t("errors.mustBeAValidEmailAdress") }}
      </div>
      <div
          class="mt-4 settings-button d-inline-block"
          @click="startMetaSetUpdate"
      >{{ $t("start") }}
        <icon class="ml-1" type="cube"/>
      </div>
    </div>
    </div>
  </div>
</template>

<script>
import Icon from "../Icon";
import InfoHelper from "@/components/InfoHelper";
import {decodeArrayBuffer} from "@/utils";
import VuelidateMixin from "@/components/mixins/VuelidateMixin.js";
import {metaDataFileFormats, TaskManagerTaskTypes, updatableInstanceFields} from "@/enum";
import {email} from "vuelidate/lib/validators";
import DataProcessor from "../fileProcessors/DataProcessor";
import ItemMapper from "../ItemMapper";
import MetaDataUpload from "@/components/assemblies/MetaDataUpload.vue";
import RegexSelector from "@/components/assemblies/RegexSelector.vue";
export default {
  name: "InstanceUpdater",
  components: {
    MetaDataUpload,
    InfoHelper,
    Icon,
    ItemMapper,
    RegexSelector
  },
  mixins: [VuelidateMixin],
  props: {
    remoteContent: {type: Object, required: true},
    canUpdate: {type: Boolean, default: false}
  },
  data() {
    return {
      partIdentifierColumn: null,
      /**
       * the columns from the meta file (which is usually a table of some sort)
       * */
      metaDataColumns: [],
      /**
       * Sets the dropzone to loading-state while processing data
       * */
      isLoading: false,
      /**
       * the available file formats = processors for meta-files (currently Windchill Search Res only)
       * */
      metaDataFileFormats: metaDataFileFormats,
      vhubPreProcessorOptions: metaDataFileFormats,
      updatableInstanceFields: updatableInstanceFields,

      /**
       * The preprocessor will be set as
       * */
      vhubPreProcessor: "",

      email: this.$store.getters.getUserEmail(this.$store.getters.getCurrentUserId),
      sendMails: true,
      content: {},
      dataProcessor: null,
      fileContent: null,

      instanceMap: [],
      regexes: [],
      metaConfig: {
        metaSet: {
          name: null,
          id: null
        }
      }
    };
  },
  validations: {
    email: {
      email,
    },
  },
  methods: {
    setRegexes(regexes) {
      this.regexes = regexes && regexes.length ? regexes.map(item => {
        return {
          id: item.id,
          content: item.content
        }
      }) : [];
    },
    setVhubPreProcessor(vhubPreProcessor) {
      this.vhubPreProcessor = vhubPreProcessor;
    },
    setMetaDataColumns(data) {
      this.metaDataColumns = data;
    },
    reset() {
      this.metaConfig = {};
      this.metaDataColumns = [];
      this.partIdentifierColumn = null;
      this.instanceMap = [];
    },
    // 1 resume upload-dropzone upload which triggers 2
    startMetaSetUpdate() {
      this.$refs.hierarchyUpdate.resumeUpload();
    },
    // 2 when file is uploaded, start update of sfx content and
    // 3 start the pipeline task "dataprocessing"
    async fileReceived(assetId, fileName) {
      console.log('received file, starting update...')
      this.$emit('processing')
      await this.updateAssemblyContent(fileName)
          .then(async () => {
            console.log('starting update...')
            await this.triggerPipelineTask();
            this.isLoading = false;
          });
    },
    async triggerPipelineTask() {
      return await this.$store.dispatch("clientAssetToPipeline", {
        id: this.$route.params.id,
        type: TaskManagerTaskTypes.INSTANCE_UPDATING,
        config: JSON.stringify({
          ...this.content.pipeline,
          sendMails: true,
          email: this.email,
          lang: this.$i18n.locale ? this.$i18n.locale : 'en',
        })
      })
    },
    async parseData() {
      this.dataProcessor = new DataProcessor(this.vhubPreProcessor);
      await this.dataProcessor.init();
      const file = decodeArrayBuffer(this.fileContent);
      this.metaDataColumns = await this.dataProcessor.getColumnNames(file);
    },
    queueEmpty: function () {
      this.metaConfig = {};
      this.metaDataColumns = [];
    },
    async updateAssemblyContent(clientMetaFile) {
      this.content.pipeline = {
        files: [`${this.$route.params.id}/${clientMetaFile}`],
        sendMails: this.sendMails,
        email: this.email,
        lang: this.$i18n.locale ? this.$i18n.locale : "en",
        dataProcessor: this.vhubPreProcessor,
        meta: {
          clientMetaFile,
          regexes: this.regexes,
          instanceMap: this.instanceMap,
          partIdentifierColumn: this.partIdentifierColumn,
          ...this.metaConfig,
        },
      };
      console.log('updating assembly content...', this.content)
      return await this.$store.dispatch("loadAssembly", {
        id: this.$route.params.id
      }).then(async () => {
        return await this.$store.dispatch("updateAssembly", {
          id: this.$route.params.id,
          content: JSON.stringify(this.content),
        });
      });
    },
  }
}
</script>

<style scoped>
.instance-data-updater {
  max-width: 600px;
}
</style>