<template>
  <div class="license-view">
    <popup
        v-if="showDeletePrompt"
        @close="() => {showDeletePrompt = false;}"
    >
      <delete-prompt slot="popupMainContent"
                     @abort="() => {showDeletePrompt = false;}"
                     @confirm="() => {deleteLicense(); showDeletePrompt = false;}"
      />
    </popup>
    <div
        v-for="license in licenses"
        :key="license.id + 'license'"
        class="bg-dark p-2 border-radius mb-2"
    >
      <h3 class="license-name"><b>{{ license.name }}</b></h3>
      <div class="settings-button d-inline-block mr-3" @click="editing = JSON.parse(JSON.stringify(license))">edit</div>
      <div class="settings-button d-inline-block" @click="deleteLicense(license.id)">delete</div>
      <br/><br/>
      <div class="d-inline-block mr-3">
        <div class="vform-label white">
          {{ $t('state') }}
        </div>
        <div class="license-state mb-3">{{ license.state }}</div>
      </div>
      <div class="d-inline-block mr-3">
        <div class="vform-label white">
          {{ $t('startsAt') }}
        </div>
        <div class="license-date mb-3">{{ license.startDate ? license.startDate : '-' }}</div>
      </div>
      <div class="d-inline-block mr-3">
        <div class="vform-label white">
          {{ $t('expiresAt') }}
        </div>
        <div class="license-date">{{ license.expirationDate ? license.expirationDate : '-' }}</div>
      </div>
      <div class="d-inline-block mr-3">
        <div class="vform-label white">
          {{ $t('price') }}
        </div>
        <div class="license-date">{{ license.price ? license.price : '0' }} CHF</div>
      </div>
      <div class="d-inline-block mr-3">
        <div class="vform-label white">
          {{ $t('licenseType') }}
        </div>
        <div class="license-date">{{ license.type }}</div>
      </div>
    </div>
    <br/>
    <div class="settings-button d-inline-block" @click="addNew">add new</div>
    <!-- EDIT VIEW  -->
    <div v-if="editing" class="edit-view">
      <div class="vform-label white">
        {{ $t('name') }}
      </div>
      <input v-model="editing.name" class="form-text border-radius" type="text"/>
      <div class="vform-label white">
        {{ $t('startsAt') }}
      </div>
      <date-picker-widget :value="editing.startDate"
                          type="date"
                          @save="updateStartDate"
      />
      <div class="vform-label white">
        {{ $t('expiresAt') }}
      </div>
      <date-picker-widget :value="editing.expirationDate"
                          type="date"
                          @save="updateExpirationDate"
      />

      <div class="vform-label white">
        {{ $t('price') }}
      </div>
      <input v-model="editing.price" class="form-text border-radius" type="text"/>

      <template v-if="!editing.id">
        <div class="vform-label white">
          {{ $t('licenseType') }}
        </div>
        <select v-model="editing.type" class="custom-select dark d-inline-block" @change="setType">
          <option value="viewer">{{ $t('viewer') }}</option>
          <option value="viewer">{{ $t('editor') }}</option>
        </select>
      </template>

      <div class="settings-button d-inline-block mt-3 mr-3" @click="editing = null">{{ $t('abort') }}</div>
      <div class="settings-button d-inline-block mt-3 mr-3" @click="updateItem()">{{ $t('save') }}</div>

    </div>
  </div>
</template>

<script>
import DatePickerWidget from "./widgets/editors/DatePickerWidget";
import Popup from "./Popup";
import DeletePrompt from "./forms/DeletePrompt";

export default {
  name: "Licenses",
  components: {
    DatePickerWidget,
    Popup,
    DeletePrompt
  },
  props: {
    userId: {type: String, required: false, default: ""}
  },
  data() {
    return {
      licenses: [],
      editing: null,
      toBeDeleted: null,
      showDeletePrompt: false
    }
  },
  beforeMount() {
    this.loadLicenses();
  },
  methods: {
    setType(event) {
      this.editing.type = event.target.value;
    },
    loadLicenses() {
      let payload = {};
      if (this.userId) {
        payload.filter = 'userId eq ' + this.userId;
      }
      this.$store.dispatch('clientLoadLicenses', payload).then(licenses => {
        this.licenses = licenses;
        this.editing = null;
      })
    },
    updateStartDate(item) {
      this.editing.startDate = item;
    },
    updateExpirationDate(item) {
      this.editing.expirationDate = item;
    },
    updateItem() {
      const {id, name, userId, expirationDate, startDate, price} = this.editing;
      let args = {name, userId, expirationDate, startDate, price};
      if (!id) {
        args.type = this.editing.type;
        this.createLicense(args);
      } else {
        args.id = id;
        this.updateLicense(args);
      }
    },
    updateLicense(params) {
      this.$store.dispatch('clientUpdateLicense', params)
          .then(() => {
            this.loadLicenses();
          })
    },
    createLicense(params) {
      this.$store.dispatch('clientCreateLicense', params)
          .then(() => {
            this.loadLicenses();
          })
    },
    deleteLicense(id = null) {
      if (id) {
        this.toBeDeleted = id;
        this.showDeletePrompt = true;
      } else {
        this.$store.dispatch('clientDeleteLicense', [this.toBeDeleted])
            .then(() => {
              this.loadLicenses()
              this.toBeDeleted = null;
            })
      }

    },
    addNew() {
      this.editing = {
        id: null,
        name: "license name",
        userId: this.userId,
        expirationDate: null,
        startDate: null,
        price: 0,
        type: "viewer"
      }
    },
  }
}
</script>

<style scoped>
.license-view {
  font-size: 1rem;
}
</style>