<template>
  <div>
   <!-- <mobile-material-setter v-if="materialEditInstanceId"
                            :asset-id="materialEditAssetId"
                            :instance-id="materialEditInstanceId"
                            :name="materialEditName"
                            :organization-id="organizationId"
                            :project-id="projectId"
                            :type="materialEditType"
                            class="p-2 border-radius popup"
                            @close="resetMaterialPopup"
    />-->
    <div v-if="initiallySelectedNode" class="tree special-scrollbar">
      <node
          v-if="projectId && localNode.name"
          :id="initiallySelectedNode"
          :data="[localNode]"
          :fields="fields"
          :has-write-access="hasWriteAccess"
          :node-close-handler="closeHandler"
          :node-open-handler="openHandler"
          :node-selection-handler="selectionHandler"
          :open-items="[initiallySelectedNode]"
          :project-id="projectId"
          @materialEdit="(item) => {materialEditInstanceId = item.id; materialEditAssetId = item.asset.id; materialEditName = item.name; materialEditType = item.type}"
          @updated="$emit('updated')"
      />
    </div>
    <div v-else-if="Object.keys($store.getters.getAssemblyTreeLevel(projectId, projectId)).length"
         class="tree special-scrollbar">
      <node
          v-if="projectId && this.$store.getters.getAssemblyTreeRootNodeId(projectId, projectId)"
          :id="$store.getters.getAssemblyTreeRootNodeId(projectId)"
          :data="$store.getters.getAssemblyTreeLevel(projectId, projectId)"
          :fields="fields"
          :has-write-access="hasWriteAccess"
          :level="1"
          :max-init-depth="maxInitDepth"
          :node-close-handler="closeHandler"
          :node-open-handler="openHandler"
          :node-selection-handler="selectionHandler"
          :open-items="openItems ? Object.keys(openItems) : []"
          :project-id="projectId"
          :show-meta="showMeta"
          @materialEdit="(item) => {materialEditInstanceId = item.id; materialEditAssetId = item.asset.id; materialEditName = item.name; materialEditType = item.type}"
          @updated="$emit('updated')"
      />
    </div>
    <div v-else-if="!intialLoading" class="mt-1 ml-4 lighter">
      {{ $t('nodata') }}
    </div>
    <div v-else>
      <div class="col-12 text-center mt-2">
        <loading-spinner style="margin:auto"/>
      </div>
    </div>
  </div>
</template>

<script>
import Node from "./LazyNode";
import {mapState} from 'vuex';
import LoadingSpinner from "@/components/LoadingSpinner";
//import MobileMaterialSetter from "../MobileMaterialSetter";

export default {
  name: 'Hierarchy',
  components: {
    Node,
    LoadingSpinner,
    //MobileMaterialSetter,
  },
  props: {
    projectId: {type: String, required: true,},
    id: {type: String, required: true,},
    fields: {type: Array, required: true,},
    data: {type: Object, default: null},
    selectionHandler: {type: String, default: ''},
    openHandler: {type: String, default: ''},
    closeHandler: {type: String, default: ''},
    maxInitDepth: {type: Number, default: 2,},
    showMeta: {type: Boolean, default: false},
    initiallySelectedNode: {type: String, default: null},
    hasWriteAccess: {type: Boolean, default: true},
    organizationId: {type: String, required: true}
  },
  data() {
    return {
      forceReRenderKey: 0,
      openItems: {},
      intialLoading: true,
      localNode: {},
      materialEditInstanceId: null,
      materialEditAssetId: null,
      materialEditName: "",
      materialEditType: ""
    };
  },
  computed: mapState({
    AssemblyListOptions: state => state.dynamicStore.AssemblyListOptions,
  }),
  watch: {
    initiallySelectedNode() {
      this.$forceUpdate();
      this.forceReRenderKey++;
    },
    AssemblyListOptions: {
      deep: true,
      handler() {
        this.openItems = this.AssemblyListOptions[this.$route.params.id].open;
      },
    },
    projectId(newValue) {
      if (newValue) {
        this.loadData();
      }
    },
    // eslint-disable-next-line no-unused-vars
    openItems() {
      if (this.openItems && Object.keys(this.openItems).length) {
        window.localStorage.setItem(`${this.$route.params.id}TreeStatus`, JSON.stringify(this.openItems));
      }

      this.$forceUpdate();
      this.forceReRenderKey = this.forceReRenderKey + 1;
    },
  },
  mounted() {
    this.init()
  },
  methods: {
    async init() {
      if (this.initiallySelectedNode) {
        await this.loadNode(this.initiallySelectedNode);
      } else if (window.localStorage.getItem(`${this.$route.params.id}TreeStatus`)) {
        this.openItems = JSON.parse(window.localStorage.getItem(`${this.$route.params.id}TreeStatus`));
        if (this.openItems) {
          this.setItemsOpen(this.openItems);
        }
      }
      await this.loadData(!this.initiallySelectedNode);
    },
    resetMaterialPopup() {
      this.materialEditName = "";
      this.materialEditInstanceId = null;
      this.materialEditAssetId = null;
      this.materialEditType = null;
    },

    /**
     * @params items an object like this:
     * {
     *   [firstId]: 'open',
     *   [secondId]: 'open'
     * }
     * */
    setItemsOpen(items) {
      for (let i = 0; i < Object.keys(items).length; i++) {
        this.$store.dispatch('setAssemblyTreeOpen', {listName: this.$route.params.id, id: Object.keys(items)[i]});
      }
    },
    async loadNode(nodeId) {
      return await this.$store.dispatch('clientLoadProjectInstance', {
        id: this.projectId,
        cid: nodeId,
        include: 'assetAndMetaSetsBugfix,sources',
      }).then(async res => {
        const level = res.level;
        let parentId = res.parentId;
        let parents = {};
        await this.loadLevelById(res.id, level);
        for (let i = 1; i < level; i++) {
          parents[parentId] = 'open';
          const levelData = await this.loadLevelById(parentId);
          parentId = levelData[0].parentId;
        }
        await this.setSelected(res);
        this.setItemsOpen(parents);
        if (this.initiallySelectedNode) {
          this.localNode = res;
        }
      })
    },
    async loadLevelById(id) {
      return this.$store.dispatch('loadAssemblyTreeLevel', {
        id: this.projectId,
        listName: this.projectId,
        include: 'assetAndMetaSets,sources,metaFields',
        limit: 200,
        filter: 'id eq ' + id
      });
    },
    async setSelected(item) {
      await this.$store.dispatch(this.selectionHandler, {
        listName: this.$route.params.id,
        id: item.id,
        assetId: item.assetId,
        type: item.asset.type,
        asset: item.asset,
        item: item
      });
    },
    async loadData(setSelected = true) {
      return this.$store.dispatch('loadAssemblyTreeLevel', {
        id: this.projectId,
        listName: this.projectId,
        include: 'assetAndMetaSets,sources,metaFields',
        limit: 200,
        level: 1,
        filter: 'level eq 1, type in model node',
        parentId: this.projectId,
      }).then(() => {
        if(setSelected) {
          let firstItem = this.$store.getters.getAssemblyTreeLevel(this.projectId, this.projectId)[0];
          if (firstItem) {
            this.$store.dispatch(this.selectionHandler, {
              listName: this.$route.params.id,
              id: firstItem.id,
              assetId: firstItem.assetId,
              type: firstItem.asset.type,
              asset: firstItem.asset,
              item: firstItem
            });
            this.$store.dispatch('loadAssemblyTreeLevel', {
              id: this.projectId,
              listName: this.projectId,
              parentId: this.$store.getters.getAssemblyTreeRootNodeId(this.projectId, this.projectId),
              include: 'assetAndMetaSets,sources,metaFields',
              limit: 200,
              level: 2,
              filter: 'level eq 2, type in model node'
            });
          }
        }
        this.intialLoading = false;
      });
    },
  },
}
</script>
<style lang="scss">
.highlighted-row {
  border: 2px solid $highlight;
}

.tree {
  width: 100%;
  padding-right: 8px;
  padding-top: 0;

  //todo: fix overflowscroll
  overflow: auto;
  overflow-y: scroll;
  max-height: 80vh;

  ul {
    width: 100%;
    list-style: none;
    padding: 0px 0px;
    margin: 0;
    color: #fff;
    //border-bottom: 1px solid $table-border-color;
    -webkit-transition: max-height 300ms;
    transition: max-height 300ms;
    background: lighten($panel-background-color, 10%);
    /*overflow: hidden;*/
    // "height: 0" not work with css transitions
    max-height: 0;

    svg {
      -webkit-transition: all 300ms ease;
      transition: all 300ms ease;
    }


    &.open {
      max-height: 10000vh;

      .text.open .item-count {
        .icon {
          -webkit-transform: translate(-50%, -50%) rotate(90deg);
          transform: translate(-50%, -50%) rotate(90deg);

          &:hover {
            opacity: 1;
          }
        }
      }

    }

    .hover-icons {
      display: none;
      position: absolute;
      right: 35px;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);

      .icon {
        -webkit-transition: all 300ms ease;
        transition: all 300ms ease;
      }

      .icon-container:hover .icon {
        opacity: 1;
      }
    }

    .text {
      padding: 2px 0px 2px 8px;
      width: 100%;
      //position: relative;
      cursor: pointer;
      position: sticky;
      top: 0;
      background: #7B7872;

      &.has-children {
        background-color: #1e1e21;
        border-bottom: 1px solid $table-border-color;
        border-top: 1px solid $table-border-color;
      }

      &:hover, &.active {
        background-color: $highlight;

        .hover-icons {
          display: flex;
        }
      }

      .linked-data {
        /*float:right;
        margin-right:35px;*/
        position: absolute;
        right: 85px;
        top: 50%;
        transform: translateY(-50%);
      }

      .item-count {
        // background:$tab-item-background;
        height: 100%;
        padding: 10px 15px;
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;
        -webkit-transition: all 300ms ease;
        transition: all 300ms ease;
        /*border-top: 1px solid $table-border-color;
        border-bottom: 1px solid $table-border-color;*/

        .icon {
          position: absolute;
          top: 50%;
          left: 50%;
          -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          -webkit-transition: all 500ms ease;
          transition: all 500ms ease;
        }

        &:hover .icon {
          -webkit-transform: translate(-50%, -50%) rotate(90deg);
          transform: translate(-50%, -50%) rotate(90deg);
        }
      }
    }

    &.level-2 {
      li {
        background: lighten($panel-background-color, 10%);
      }

      .text {
        padding-left: 1.6em;
      }
    }

    &.level-3 {
      .text {
        padding-left: 3.1em;
      }
    }

    &.level-4 {
      .text {
        padding-left: 4em;
      }
    }

    &.level-5 {
      .text {
        padding-left: 5em;
      }
    }

    &.level-6 {
      .text {
        padding-left: 6em;
      }
    }

    &.level-7 {
      .text {
        padding-left: 7.5em;
      }
    }

    &.level-8 {
      .text {
        padding-left: 8em;
      }
    }

    &.level-9 {
      .text {
        padding-left: 8.5em;
      }
    }

    &.level-10 {
      .text {
        padding-left: 9em;
      }
    }

    &.level-11 {
      .text {
        padding-left: 9.5em;
      }
    }

    &.level-12 {
      .text {
        padding-left: 10em;
      }
    }
  }

  li {

    div.text {
      position: -webkit-sticky; /* Safari */
      position: sticky;
      top: 0px;
      border-bottom: 1px solid $table-border-color;
    }

    .node-name {
      max-width: 80%;
      padding-left: 19px;
      font-size: 0.9rem;
    }

    .icon-left {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }

    .half-circle-spinner {
      display: inline-block;
    }

    .editing-icon {
      position: absolute;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);

      &:nth-child(1) {
        right: 30px;
      }

      &:nth-child(2) {
        right: 0px;
      }
    }

    .toggle-icon {
      height: 100%;
      background: red;
      position: absolute;
      right: 15px;
      padding: 5px;
      top: 50%;
      -webkit-transform: translateY(-50%);
      transform: translateY(-50%);
      cursor: pointer;
      transition: all 300ms ease;
      -webkit-transition: all 300ms ease;
      /*.icon {
          -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
          top:50%;
          left:50%;
      }
      &.open .icon {
          -webkit-transform: translate(-50%, -50%) rotate(90deg);
          transform: translate(-50%, -50%) rotate(90deg);
      }*/
    }
  }
}

.overflowing {
  max-height: 80vh;
  overflow: auto;
  overflow-y: scroll;
}

.special-scrollbar {
  -ms-overflow-style: scrollbar;
  //&::-webkit-scrollbar { width: 0 !important }
  /* width */
  &::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background-color: #171615;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background-color: $highlight;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background-color: $highlight;
  }
}

.lazy-meta-box {
  display: inline-block;
}
</style>