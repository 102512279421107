<template>
  <div>
    <popup v-if="showPopup" @close="handleClose">
      <div class="justify-content-center text-center" slot="popupMainContent">
        <h2><icon class="mr-1" type="robot" /> Important Notice</h2>
        <p class="form-error p-3 mb-2 margin-auto">We are currently working on speed improvements for vHUB / vSTAGE. As a result, the service will be unavailable from Friday, December 6th at 11:55 PM until Sunday, December 8th at 11:55 PM (expected).<br />The offline mode of vSTAGE will remain unaffected.
          <br />Thank you for your understanding!</p>
        <p class="form-error p-3 mb-2 margin-auto">Wir arbeiten gerade an Verbesserungen der Geschwindigkeit von vHUB / vSTAGE. Daher wird vHUB von Freitag, den 6. Dezember, 23:55 bis voraussichtlich Sonntag, den 8. Dezember, 23:55 nicht erreichbar sein.<br />Der Offline-Modus von vSTAGE bleibt jedoch unbeeinträchtigt.<br />
          Vielen Dank für Ihr Verständnis!</p>
      </div>
    </popup>
  </div>
</template>

<script>
import Popup from "@/components/Popup.vue";
import Icon from "@/components/Icon.vue";

export default {
  name: "ImportantNoticePopup",
  components: {
    Popup,
    Icon
  },
  data() {
    return {
      showPopup: false,
      popupKey: 'popup-2'
    };
  },
  created() {
    this.checkPopupStatus();
  },
  methods: {
    checkPopupStatus() {
      const hasSeenPopup = localStorage.getItem(this.popupKey);
      if (!hasSeenPopup) {
        this.showPopup = true;
      }
    },
    handleClose() {
      this.showPopup = false;
      localStorage.setItem(this.popupKey, "true");
    },
  },
};
</script>
