<template>
  <main-table-layout
      subMenuSelector="adminpanel"
      tabSelector="licenseoverview"
      type="license"
  >
    <div slot="mainContent">
      <popup
          @close="() => {showDeletePrompt = false;}"
          v-if="showDeletePrompt"
      >
        <delete-prompt
            slot="popupMainContent"
            @confirm="deleteLicense"
            @cancel="showDeletePrompt = false"
        />
      </popup>
      <div class="license-overview">
        <div class="license-overview-header">
          <h2>{{ $t('Licenses') }}</h2>
          <div v-if="organizationId" class="settings-button d-inline-block" @click="addNew">{{ $t('add new') }}</div>
        </div>
        <organization-switcher
            :organizations="$store.getters.getOrganizations"
            :show-teams="false"
            @setOrganization="setOrganization"
        />
        <div @click="organizationId = null; loadLicenses()" class="settings-button d-inline-block mt-2">
          load all licenses
        </div>
        <div v-if="editing">
          <license-form
              v-if="editing && (editing.id || organizationId)"
              :license="editing"
              :users="users"
              @submit="handleSubmit"
              @abort="editing = null"
          />
        </div>
        <div class="license-overview-content">
          <div v-for="license in licenses" :key="license.id" class="license-overview-item">
            <div class="d-inline-block mr-3">
              <div class="vform-label white">
                {{ $t('state') }}
              </div>
              <div class="license-state mb-3">{{ license.state }}</div>
            </div>
            <div class="d-inline-block mr-3">
              <div class="vform-label white">
                {{ $t('startsAt') }}
              </div>
              <div :class="getDateClass(license.startDate, true)" class="license-date mb-3">{{ license.startDate ? license.startDate : '-' }}</div>
            </div>
            <div class="d-inline-block mr-3">
              <div class="vform-label white">
                {{ $t('expiresAt') }}
              </div>
              <div :class="getDateClass(license.expirationDate)" class="license-date">{{ license.expirationDate ? license.expirationDate : '-' }}</div>
            </div>
            <div class="d-inline-block mr-3">
              <div class="vform-label white">
                {{ $t('price') }}
              </div>
              <div class="license-date">{{ license.price ? license.price : '0' }} CHF</div>
            </div>
            <div class="d-inline-block mr-3">
              <div class="vform-label white">
                {{ $t('licenseType') }}
              </div>
              <div class="license-date">{{ license.type }}</div>
            </div>
            <div class="d-inline-block mr-3">
              <div class="vform-label white">
                {{ $t('userName') }}
              </div>
              <div class="license-date">{{ getUserName(license.userId) }}</div>
            </div>
            <div class="d-inline-block mr-3">
              <div class="vform-label white">
                {{ $t('organization') }}
              </div>
              <div class="license-date">{{ getOrganization(license.organizationId) }}</div>
            </div>
            <div class="d-inline-block mr-3">
              <div class="settings-button d-inline-block mr-2" @click="editLicense(license)">Modify</div>
              <div class="settings-button d-inline-block" @click="confirmDelete(license.id)">Delete</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </main-table-layout>
</template>

<script>
import MainTableLayout from "@/layouts/MainTableLayout.vue";
import OrganizationSwitcher from "@/components/organizations/OrganizationSwitcher.vue";
import LicenseForm from "@/components/licenses/LicenseForm.vue";
import DeletePrompt from "@/components/forms/DeletePrompt.vue";
import Popup from "@/components/Popup.vue";

export default {
  name: "LicenseOverview",
  components: {
    Popup,
    OrganizationSwitcher,
    MainTableLayout,
    LicenseForm,
    DeletePrompt
  },
  data() {
    return {
      licenses: [],
      organizationId: '',
      editing: {},
      showDeletePrompt: false,
      toBeDeleted: null,
      users: []
    };
  },
  watch: {
    organizationId() {
      this.loadUsers();
    }
  },
  methods: {
    loadUsers() {
      this.$store.dispatch('clientLoadUsers', {
        filter: 'homeOrganizationId eq ' + this.organizationId
      }).then(users => {
            this.users = users;
          });
    },
    getUserName(userId) {
      const user = this.users.find(user => user.id === userId);
      return user ? user.name : 'N/A';
    },
    getOrganization(organizationId) {
      const organization = this.$store.getters.getOrganizationById(organizationId);
      return organization ? organization.name : 'organization name not found';
    },
    addNew() {
      this.editing = {
        id: null,
        name: '',
        startDate: '',
        expirationDate: '',
        price: '',
        type: '',
      };
    },
    editLicense(license) {
      this.editing = { ...license };
    },
    confirmDelete(id) {
      this.toBeDeleted = id;
      this.showDeletePrompt = true;
    },
    deleteLicense() {
      this.$store.dispatch('clientDeleteLicense', [this.toBeDeleted])
          .then(() => {
            this.loadLicenses();
            this.toBeDeleted = null;
            this.showDeletePrompt = false;
          });
    },
    setOrganization(id) {
      this.organizationId = id;
      this.loadLicenses();
    },
    loadLicenses() {
      let payload = {};
      if (this.userId) {
        payload.filter = 'userId eq ' + this.userId;
      }
      if(this.organizationId) {
        payload.filter = 'organizationId eq ' + this.organizationId;
      }
      this.$store.dispatch('clientLoadLicenses', payload)
          .then(licenses => {
            this.licenses = licenses;
            this.editing = null;
          })
    },
    async updateLicense(license) {
      this.$store.dispatch('clientUpdateLicense', license)
          .then(() => {
            this.loadLicenses();
          });
    },
    async createLicense(license) {
      license.organizationId = this.organizationId;
      this.$store.dispatch('createLicense', license)
          .then(() => {
            this.loadLicenses();
          });
    },
    async handleSubmit(form) {
      if (form.id) {
        await this.updateLicense(form);
      } else {
        await this.createLicense(form);
      }
    },
    getDateClass(date, isStartDate = false) {
      const today = new Date();
      const targetDate = new Date(date);
      if (isStartDate) {
        if (targetDate > today) {
          return 'date-grey';
        } else {
          return 'date-green';
        }
      } else {
        if (targetDate > today) {
          return 'date-grey';
        } else if (targetDate < today) {
          return 'date-red';
        } else {
          return 'date-green';
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.date-grey {
  color: grey;
}
.date-red {
  color: red;
}
.date-green {
  color: green;
}
</style>