<template>
    <popup
        @close="$emit('abort')"
    >
      <div slot="popupMainContent">
        <model-edit-view
            :instance-id="instanceId"
            :project-id="projectId"
            :has-write-access="hasWriteAccess"
            @updateName="updateName"
            @updated="$emit('updated')"
        />
      </div>

      <!--<Button
              slot="popupFooter"
              class="mb-3"
              :deactivated="!enableSaving"
              v-on:click="save"
      >
          <i v-if="enableSaving" class="far fa-save"></i><i v-else class="fa fa-ban" aria-hidden="true"></i>
          {{$t('SAVE')}}
      </Button>-->
    </popup>
</template>

<script>
import Popup from "@/components/Popup";
import ModelEditView from "@/components/tree/ModelEditView";
export default {
name: "ModelEditViewPopup",
  components: {
    Popup,
    ModelEditView,
  },
  props: {
    instanceId: {type: String, required: true,},
    projectId: {type: String, required: true,},
    hasWriteAccess: {type: Boolean, default: true}
  },
  methods: {
    updateName(instanceId, instanceName) {
      this.$emit('updateName', instanceId, instanceName);
    },
  }
}
</script>