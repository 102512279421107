<template>
    <div>
      <div class="dflex mt-3">
        <div class="vform-hint mb-3">{{ $t('partLegendHint') }}</div>
        <div class="col row">
          <label class="vform-editor-label section mt-0">{{ $t('vform.titleAlignment') }}</label>
        </div>
        <div
            v-for="(align, index) in titleAlignments"
            :key="index"
            :class="['vform-alignment-button', align === element.titleAlignment ? 'active' : '']"
            @click="setTitleAlignment(align)">
          <icon :type="'align-' + align" size="1"/>
        </div>
      </div>
      <div class="dflex mt-3">
        <div class="col row">
          <label class="vform-editor-label section mt-0">{{ $t('vform.textAlignment') }}</label>
        </div>
        <div
            v-for="(align, index) in textAlignments"
            :key="index"
            :class="['vform-alignment-button', align === element.textAlignment ? 'active' : '']"
            @click="setTextAlignment(align)">
          <icon :type="'align-' + align" size="1"/>
        </div>
      </div>
      <label class="vform-editor-label section">Border Color</label>
      <set-by-template v-if="!templateEditing && !isObjectEmpty(selectedTemplate)" />
      <div
          v-else
          :style="{
                  width: '20px',
                  height: '20px',
                  backgroundColor: element.borderColor ? element.borderColor : '',
                  border: element.borderColor ? '' : '1px dashed rgba(255,255,255,0.5)'
              }"
          class="clickable"
          @click="(event) => {
            setColorPickrId(event, 'borderColor');
          }"
      />
      <div
          v-if="element.borderColor"
          class="settings-button d-inline-block mt-2"
          @click="element.borderColor = null;">
        {{ $t('resetColor') }}
      </div>
      <div class="col row dflex mt-4">
        <div class="dflex">
          <label :for="'chkEmphasized' + $vnode.key" class="container vform-editor-body">{{ $t("emphasized") }}
            <input
                :id="'chkEmphasized' + $vnode.key"
                v-model="element.emphasized"
                type="checkbox"
            >
            <span class="checkmark"/>
          </label>
        </div>
      </div>
      <meta-fields-selector
          :selected-meta-fields="element.metaFields ? element.metaFields : []"
          :organization-id="organizationId"
          :include-super-org-metasets="includeSuperOrgMetasets"
          @updateMetaFieldSelection="updateMetaFieldSelection"
      />
      <color-picker
        v-if="!templateEditing"
        v-show="colorPickrId"
        :key="colorPickrId"
        ref="colorpickr"
        :popup-style="true"
        :show-save-button="true"
        :template-editing="templateEditing"
        :value="element[colorPickrId] ? element[colorPickrId] : '#000'"
        class="step-settings-color-pickr"
        @changeColor="(color) => { setItemColor(colorPickrId, color, false); }"
        @close="() => { colorPickrId = null;}"
        @save="(color) => { setItemColor(colorPickrId, color); }"
      />
    </div>
</template>
  
  <script>
  import {vFormControls} from "@/enum";
  import Icon from "../Icon";
  import vFormElementMixin from "@/components/vForm/vFormElementMixin.js";
  import ColorPicker from "@/components/widgets/editors/ColorPicker";
  import MetaFieldsSelector from "@/components/vForm/MetaFieldsSelector.vue";
  export default {
    name: "VFormPARTLEGEND",
    components: {
      Icon,
      ColorPicker,
      MetaFieldsSelector
    },
    mixins: [vFormElementMixin],
    data() {
      return {
        vFormControls: vFormControls,
        titleAlignments: ["left", "center", "right"],
        textAlignments: ["left", "center", "right"],
        includeSuperOrgMetasets: ["sfx.asset.model", "sfx.asset.transform", "sfx.asset.assembly"],
      };
    },
    beforeMount() {
      this.element.formElementType = vFormControls["PART_LEGEND"];
      if (!this.element.titleAlignment) {
        this.$set(this.element, "titleAlignment", "left");
      }
      if (!this.element.textAlignment) {
        this.$set(this.element, "textAlignment", "left");
      }
    },
    methods: {
      updateMetaFieldSelection(selectedMetaFields) {
        console.log('selectedMetaFields', selectedMetaFields);
        this.$set(this.element, "metaFields", selectedMetaFields);
      },
      setTitleAlignment(val) {
        this.$set(this.element, "titleAlignment", val);
      },
      setTextAlignment(val) {
        this.$set(this.element, "textAlignment", val);
      },
    }
  };
  </script>
  
  <style lang="scss">
  .vform-alignment-button {
    background-color: var(--vform-editor-ui-secondary-color);
    border-radius: 1px;
    margin-left: 1px;
    width: 25px;
    height: 25px;
    position: relative;
    color: #fff;
  
    .icon {
      position: absolute;
      top: 50%;
      left: 50%;
      -webkit-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
    }
  
    &.active {
      background-color: var(--vform-editor-accent-color);
    }
  }
  .vform-hint {
    border-radius: 3px;
    padding: 3px;
    font-size: 0.8rem;
    background-color: var(--vform-editor-ui-secondary-color);
    color: var(--vform-editor-ui-primary-color);
  }
  </style>