<template>
  <div>
    <div class="form-section" v-if="showPartSelection">
      <h2>{{ $t('SpecifyColumns') }}</h2>
      <label class="mb-0">{{ $t('chooseModelIdField') }}*</label><br>
      <select @change="setConfig" class="mb-3 custom-select darker standard-height" v-model="partIdentifierColumn">
        <option :key="index" v-for="(key, type, index) in metaDataColumns" :value="key">{{ key }}</option>
      </select>
      <div class="form-error" v-if="partIdentifierMissing">{{ $t('errors.required') }}</div>
    </div>
      <meta-set-selector
          ref="metaSelect"
          v-if="organizationId"
          :can-create-new="true"
          :show-save-button="false"
          :organization-id="organizationId"
          :exclude="['sfx.asset.model']"
          :meta-set-prefix="'asset.model.' + $store.getters.getOrganizationName(organizationId)"
          @update="setMetaSet"
          :teams="teams"
          :permission="permission"
      />
  </div>
</template>

<script>
import MetaSetSelector from "@/components/widgets/meta/MetaSetSelector";
export default {
  name: "AssemblyConfig",
  components: {
    MetaSetSelector
  },
  props: {
    metaDataColumns: {type: Array, default: () => {return []}},
    organizationId: {type: String, required: true},
    teams: {type: Array, default: () => {}},
    permission: {type: String, default: ''},
    showPartSelection: {type: Boolean, default: true},
  },
  data() {
    return {
      metaSetName: '',
      metaSetId: '',

      /**
       * these are the columns of the metaData file which will be used for pre/postprocessing
       * */
      partIdentifierColumn: '',
      colorColumn: '',
      materialColumn: '',

      /**
       * currently this is exactly the content.pipeline.meta part
       * */
      config: {},

      partIdentifierMissing: false,
    };
  },
  methods: {
    setConfig() {
      this.partIdentifierMissing = false;
      if(this.metaSetId && this.metaSetName) {
        this.config.metaSet = {
          name: this.metaSetName,
          id: this.metaSetId
        };
      }
      if(this.partIdentifierColumn) {
        this.config.partIdentifierColumn = this.partIdentifierColumn;
      }
      if(this.colorColumn) {
        this.config.colorColumn = this.colorColumn;
      }
      if(this.materialColumn) {
        this.config.materialColumn = this.materialColumn;
      }
      this.config.columns = this.metaDataColumns;
      this.$emit('setConfig', this.config);
    },
    setMetaSet(metaSetName, metaSetId) {
      this.metaSetName = metaSetName;
      this.metaSetId = metaSetId;
      this.setConfig();
    },
    async save() {
      if(!this.partIdentifierColumn) {
        this.partIdentifierMissing = true;
        throw new Error('part identifier is missing');
      }
      if(!this.metaSetId || this.metaSetId === 999) {
        if(this.$refs.metaSelect) {
          const newMetaSet = await this.$refs.metaSelect.createNewMetaSet();
          this.metaSetId = newMetaSet.id;
          this.metaSetName = newMetaSet.name;
        }
      }
    },
  }
}
</script>