<template>
    <span :class="['icon', isSfxIcon ? 'sfx-icon' : '' ]" :style="getSize">
        <font-awesome-icon v-if="getIcon" :class="injectedSvgClass" :icon="getIcon"/>
        <component :is="type" v-if="isSfxIcon" :style="{ height: getSfxIconSize, width: getSfxIconSize }"/>
    </span>
</template>

<script>
/**
 * Icon
 */
import {library} from '@fortawesome/fontawesome-svg-core';
import {
  faIdCard,
  faBullhorn,
  faPowerOff,
  faColumns,
  faMapMarker,
  faPause,
  faSignOutAlt,
  faAlignRight,
  faAlignCenter,
  faAlignJustify,
  faStepBackward,
  faStepForward,
  faCodeBranch,
  faDollarSign,
  faExclamationTriangle,
  faPuzzlePiece,
  faSpinner,
  faGripVertical,
  faBatteryHalf,
  faWrench,
  faServer,
  faHdd,
  faChartPie,
  faThermometerHalf,
  faStopCircle,
  faPlayCircle,
  faDesktop,
  faCameraRetro,
  faGhost,
  faKiwiBird,
  faBabyCarriage,
  faChild,
  faBaby,
  faRobot,
  faTasks,
  faCopy,
  faDownload,
  faClock,
  faExternalLinkAlt,
  faRedo,
  faBoxOpen,
  faListUl,
  faCog,
  faFileExport,
  faMinus,
  faCaretDown,
  faCaretUp,
  faTrash,
  faExchangeAlt,
  faSitemap,
  faRecycle,
  faBiohazard,
  faQrcode,
  faQuestionCircle,
  faCubes,
  faMapMarkerAlt,
  faWindowMaximize,
  faBox,
  faThLarge,
  faMagic,
  faCommentAlt,
  faFont,
  faVolumeUp,
  faImage,
  faBroom,
  faFilePdf,
  faChartBar,
  faGlobeAmericas,
  faPollH,
  faCloud,
  faTrashAlt,
  faBuilding,
  faAlignLeft,
  faCheck,
  faArrowsAlt,
  faHome,
  faLock,
  faVideo,
  faAngleUp,
  faTag,
  faFileDownload,
  faBan,
  faFileWord,
  faExclamation,
  faCamera,
  faFileUpload,
  faSave,
  faEllipsisV,
  faAngleDown,
  faDatabase,
  faBook,
  faFilm,
  faMobile,
  faTimes,
  faEyeSlash,
  faEye,
  faPencilAlt,
  faUnlink,
  faLink,
  faAngleLeft,
  faAngleRight,
  faPaperclip,
  faCube,
  faRss,
  faSignal,
  faPlus,
  faTable,
  faTh,
  faUserSecret,
  faFileCsv,
  faFile,
  faUserTag,
  faUser,
  faUsers,
  faSort,
  faTimesCircle,
  faSignInAlt,
  faFolderOpen,
  faFolder,
  faFilter,
  faUpload,
  faShoePrints,
  faAngleDoubleUp,
  faAngleDoubleDown,
  faAngleDoubleLeft,
  faAngleDoubleRight,
} from '@fortawesome/free-solid-svg-icons';
import {faEdit} from '@fortawesome/free-regular-svg-icons'
import {faWindows, faApple} from '@fortawesome/free-brands-svg-icons'
import {
  faCompassDrafting,
  faPaintRoller,
  faLocationDot,
  faTicket,
  faImageLandscape,
  faImagePortrait,
  faMosquito,
  faFlask,
  faCauldron, faSquarePlus as fadSquarePlus, faDiagramLeanCanvas, faGameBoardSimple, faBlockBrick
} from '@fortawesome/pro-duotone-svg-icons'
import {
  faTableLayout,
  faThumbtack,
  faUpDownLeftRight,
  faArrowUp,
  faArrowUpLeft,
  faArrowUpRight,
  faArrowLeft,
  faArrowRight,
  faArrowDown,
  faArrowDownLeft,
  faArrowDownRight,
  faArrowUpFromLine,
  faArrowDownFromLine,
  faArrowLeftFromLine,
  faArrowRightFromLine,
  faSquareMinus as falSquareMinus,
  faSquarePlus as falSquarePlus,
  faXmark,
  faGripDotsVertical,
  faArrowsMinimize,
  faArrowsMaximize,
  faArrowsUpDown,
  faExpand,
  faCode,
  faListDropdown,
  faCompress,
  faRotateLeft,
  faArrowUpRightAndArrowDownLeftFromCenter as falFullScreen,
  faDownLeftAndUpRightToCenter as falNormalScreen,
  faPlay as falPlay,
  faStop as falStop
} from '@fortawesome/pro-light-svg-icons'
import {faSquareMinus as fatSquareMinus, faPenToSquare as fatPenToSquare} from '@fortawesome/pro-thin-svg-icons'

const loadIcons = (icons) => icons.map(icon => [icon.prefix, icon.iconName]);
const mergeIcons = (dest, src) => {
  for (const icon of src) {
    dest[icon[1]] = icon
  }
};
const icons = {
  timesCircle: ['fas', 'times-circle'],
  usertag: ['fas', 'user-tag'],
  team: ['fas users'],
  close: ['fas', 'times-circle'],
  login: ['fas', 'sign-in-alt'],
  check: ['fas', 'check'],
  sensor: ['fas', 'signal'],
  feed: ['fas', 'rss'],
  tbl: ['fas', 'file-csv'],
  obj: ['fas', 'file'],
  text: ['fas', 'align-left'],
  form: ['fa', 'poll-h'],
  report: ['fa', 'chart-bar'],
  image: ['fa', 'image'],
  audio: ['fa', 'volume-up'],
  font: ['fa', 'font'],
  message: ['fa', 'comment-alt'],
  model: ['fa', 'cube'],
  pdf: ['fa', 'file-pdf'],
  multipart: ['fa', 'magic'],
  app: ['fa', 'th-large'],
  webapp: ['fa', 'window-maximize'],
  vhelper: ['fa', 'map-marker-alt'],
  helper: ['fa', 'map-marker-alt'],
  assembly: ['fa', 'cubes'],
  cadConvert: ['fa', 'exchange-alt'],
  edit: ['far', 'pen-to-square'],
  importHierarchy: ['fas', 'sitemap'],
  sort: ['fas', 'sort'],
  h: ['fa', 'eye-slash'],
  p: ['fa', 'clock'],
  a: ['fa', 'eye'],
  i: ['fa', 'ban'],
  m: ['fa', 'wrench'],
  'far-square-plus': ['far', 'square-plus'],
  'fad-square-plus': ['fad', 'square-plus'],
  'far-square-minus': ['far', 'square-minus'],
  'fat-square-minus': ['fat', 'square-minus'],
  'fal-full-screen': ['fal', 'arrow-up-right-and-arrow-down-left-from-center'],
  'fal-normal-screen': ['fal', 'down-left-and-up-right-to-center'],
  'fal-play': ['fal', 'play'],
  'fal-stop': ['fal', 'stop'],
  editLight: ['fat', 'pen-to-square'],
  upDown: ['far', 'arrows-up-down'],
};
const importedIcons = [
  faIdCard,
  faTableLayout,
  faCompassDrafting,
  faBullhorn,
  faPowerOff,
  faColumns,
  faShoePrints,
  faThumbtack,
  faUpDownLeftRight,
  faArrowUp,
  faArrowUpLeft,
  faArrowUpRight,
  faArrowLeft,
  faArrowRight,
  faArrowDown,
  faArrowDownLeft,
  faArrowDownRight,
  faArrowUpFromLine,
  faArrowLeftFromLine,
  faArrowRightFromLine,
  faArrowDownFromLine,
  faMapMarker,
  faPause,
  faPaintRoller,
  faLocationDot,
  faTicket,
  faImageLandscape,
  faImagePortrait,
  faMosquito,
  faFlask,
  faCauldron,
  faBlockBrick,
  faGameBoardSimple,
  faDiagramLeanCanvas,
  faSignOutAlt,
  faAlignRight,
  faAlignCenter,
  faAlignJustify,
  fatPenToSquare,
  faStepBackward,
  faStepForward,
  faCodeBranch,
  faDollarSign,
  faExclamationTriangle,
  faPuzzlePiece,
  faSpinner,
  faGripVertical,
  faBatteryHalf,
  faWrench,
  faServer,
  faHdd,
  faChartPie,
  faThermometerHalf,
  faStopCircle,
  faPlayCircle,
  faDesktop,
  faGhost,
  faPaintRoller,
  faKiwiBird,
  faBabyCarriage,
  faChild,
  faBaby,
  faRobot,
  faTasks,
  faCameraRetro,
  faCopy,
  faDownload,
  faClock,
  faExternalLinkAlt,
  faRedo,
  faBoxOpen,
  faListUl,
  faCog,
  faFileExport,
  faMinus,
  faExchangeAlt,
  faSitemap,
  faRecycle,
  faBiohazard,
  faQrcode,
  faQuestionCircle,
  faCubes,
  faMapMarkerAlt,
  faWindowMaximize,
  faBox,
  faThLarge,
  faMagic,
  faCommentAlt,
  faFont,
  faVolumeUp,
  faImage,
  faBroom,
  faCaretDown,
  faCaretUp,
  faTrash,
  faFilePdf,
  faChartBar,
  faGlobeAmericas,
  faPollH,
  faCloud,
  faTrashAlt,
  faBuilding,
  faUpload,
  faCheck, faAlignLeft, faArrowsAlt, faHome, faLock, faVideo, faAngleUp, faTag, faFileDownload, faBan, faFileWord, faExclamation, faCamera, faFileUpload, faSave, faEllipsisV, faAngleDown, faDatabase, faBook, faFilm, faMobile, faTimes, faEyeSlash, faEye, faPencilAlt, faUnlink, faLink, faAngleLeft, faAngleRight, faPaperclip, faCube, faRss, faSignal, faPlus, faTable, faTh, faUserSecret, faEdit, faFileCsv, faFile, faUserTag, faUser, faUsers, faSort, faTimesCircle, faSignInAlt, faFolderOpen, faFolder, faWindows, faApple, faFilter, faAngleDoubleUp, faAngleDoubleDown, faAngleDoubleLeft, faAngleDoubleRight
];

const proIcons = [faRotateLeft, faArrowsUpDown, falSquareMinus, fatSquareMinus, falSquarePlus, fadSquarePlus, faXmark, faGripDotsVertical, faArrowsMinimize, faArrowsMaximize, faExpand, faCompress, falFullScreen, falNormalScreen, falPlay, falStop, faCode, faListDropdown];
mergeIcons(icons, loadIcons(importedIcons));
mergeIcons(icons, loadIcons(proIcons));
Object.freeze(icons);
library.add(importedIcons);
library.add(proIcons);

/* custom sfx SVG icons */
import SfxIconHome from "@/components/customIcons/SfxIconHome";
import SfxIconArrowLeft from "@/components/customIcons/SfxIconArrowLeft";
import SfxIconArrowRight from "@/components/customIcons/SfxIconArrowRight";
import SfxIconHorizontalMore from "@/components/customIcons/SfxIconHorizontalMore";

export default {
  name: 'Icon',
  components: {
    SfxIconHome,
    SfxIconArrowLeft,
    SfxIconArrowRight,
    SfxIconHorizontalMore
  },
  props: {
    /**
     * The icon type (face)
     *
     * Following icons are available:
     *
     * file-word, exclamation, camera, file-upload, save, ellips-is-v, angle-down, database, book,
     * film, mobile, times, eye-slash, eye, pencil-alt, unlink, link, angle-left, angle-right,
     * paperclip, cube, rss, signal, plus, table, th, user-secret, file-csv, file,
     * user-tag, user, users, sort, times-circle, sign-in-alt, folder-open, folder,
     * filter, edit, windows, apple, usertag, team, close, login, check, sensor, feed,
     * obj, tbl
     *
     */
    type: {type: String, required: true/*,  validator: value => value in icons*/},
    /**
     * The icon size.
     *
     * @values small, normal, large or size in em
     */
    size: {type: String, default: 'small'},
    injectedSvgClass: {type: String, default: ''}
  },
  data() {
    return {icons: icons}
  },
  computed: {
    isSfxIcon: function () {
      return this.type && this.type.startsWith("sfx-");
    },
    getIcon: function () {
      if (this.isSfxIcon) {
        return null;
      }

      if (!this.icons[this.type]) {
        console.log('icon of type ' + this.type + ' not found');
        return null;
      }

      return this.icons[this.type];
    },
    getSize: function () {
      if (this.isSfxIcon) {
        return null;
      }

      switch (this.size) {
        case 'large':
          return 'font-size: 3.42em';
        case 'normal':
          return 'font-size: 1.7em';
        case 'small':
          return 'font-size: 1.14em';
      }
      if (this.size != null) {
        return 'font-size: ' + this.size + 'em';
      }
      return null;
    },
    getSfxIconSize: function () {
      switch (this.size) {
        case 'large':
          return '3.42em';
        case 'normal':
          return '1.7em';
        case 'small':
          return '1.14em';
      }
      if (this.size != null) {
        return this.size + 'em';
      }

      return null;
    }
  },
  beforeMount() {
    //console.log(icons);
  }
}
</script>

<style lang="scss">
.sfx-icon {
  display: flex;
  justify-content: center;
  height: 100%;
  align-items: center;
}
</style>
<docs>
Vue component example:

```jsx
<div>
  Large icon
  <Icon size="large" type="filter"/>
</div>
<div>
  Normal icon
  <Icon size="normal" type="filter"/>
</div>
<div>
  Small icon
  <Icon size="small" type="filter"/>
</div>
```
</docs>
