// Vector3
class Vector3 {
  constructor(x, y, z) {
    this.x = x;
    this.y = y;
    this.z = z; // Fixed assignment
  }

  static one() {
    return new Vector3(1.0, 1.0, 1.0);
  }

  static zero() {
    return new Vector3(0.0, 0.0, 0.0);
  }
}

// Transform
class Transform {
  constructor(position, rotation, scale) {
    this.position = position || Vector3.zero();
    this.rotation = rotation || Vector3.zero();
    this.scale = scale || Vector3.one();
  }

  static zero() {
    return new Transform(Vector3.zero(), Vector3.zero(), Vector3.zero());
  }
}

// Converter

// Convert ZR (Z-Up, right-handed) to YL (Y-up, left-handed)
// Converts a 3DS-Max Transform to Unity3D Transform
function ConvertRootZRtoYL(transformZR) {
  const transformYL = Transform.zero();

  // Invert and swap axes for position
  transformYL.position = new Vector3(
    transformZR.position.x * -1,
    transformZR.position.z,
    transformZR.position.y * -1
  );

  // Adjust rotation angles
  transformYL.rotation = NormalizeEulerAngles(
    new Vector3(
      transformZR.rotation.x - 90,
      transformZR.rotation.z * -1,
      transformZR.rotation.y
    )
  );

  // Scale is assumed to remain the same
  transformYL.scale = transformZR.scale;

  return transformYL;
}

// Convert YL (Y-up, left-handed) to ZR (Z-Up, right-handed)
// Converts a Unity3D Transform to 3DS-Max Transform
function ConvertRootYLtoZR(transformYL) {
  const transformZR = Transform.zero();

  // Invert and swap axes for position
  transformZR.position = new Vector3(
    transformYL.position.x * -1,
    transformYL.position.z * -1,
    transformYL.position.y
  );

  // Adjust rotation angles
  transformZR.rotation = NormalizeEulerAngles(
    new Vector3(
      transformYL.rotation.x + 90,
      transformYL.rotation.z,
      transformYL.rotation.y * -1
    )
  );

  // Scale is assumed to remain the same
  transformZR.scale = transformYL.scale;

  return transformZR;
}

// Normalize Euler angles to range [-180, 180]
function NormalizeEulerAngles(eulerAngles) {
  return new Vector3(
    NormalizeAngle(eulerAngles.x),
    NormalizeAngle(eulerAngles.y),
    NormalizeAngle(eulerAngles.z)
  );
}

// Normalize a single angle to range [-180, 180]
function NormalizeAngle(angle) {
  while (angle > 180) angle -= 360;
  while (angle < -180) angle += 360;
  return angle;
}

export { Vector3, Transform, ConvertRootZRtoYL, ConvertRootYLtoZR, NormalizeEulerAngles, NormalizeAngle };