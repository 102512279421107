<template>
  <div class="col-12 meta-data-updater">
    <div class="form-section col-12 pb-1 mb-1">
      <!--<info-helper class="float-right" text="UpdateMetaDataInfo"
      />-->
      <h3>{{ $t("UpdateMetaData") }}</h3>
      <p class="lighter mb-1">{{ $t('metaDataUpdaterHint') }}</p>
    </div>
  <meta-data-upload
      ref="metaDataUpload"
      class="mt-3 col-12"
      @metaDataColumns="setMetaDataColumns"
      @reset="reset"
      @fileReceived="fileReceived"
      @setVhubPreProcessor="setVhubPreProcessor"
      @partIdentifierColumn="(val) => {metaConfig.partIdentifierColumn = val}"
      :asset-id="$route.params.id"
  />
  <div v-if="metaConfig.partIdentifierColumn" class="col-12 mt-2">
    <meta-set-selector
        ref="metaSelect"
        v-if="$store.getters.getAssemblyOrganization($route.params.id)"
        :can-create-new="true"
        :show-save-button="false"
        :organization-id="$store.getters.getAssemblyOrganization($route.params.id)"
        :exclude="['sfx.asset.model']"
        :meta-set-prefix="'asset.model.' + $store.getters.getOrganizationName($store.getters.getAssemblyOrganization($route.params.id))"
        @update="setMetaSet"
        :teams="$store.getters.getAssemblyTeams($route.params.id)"
        permission="writeMetaSet"
    />
  </div>
  <div
      v-if="metaConfig.metaSet.id"
      class="col-12 mt-3">
    <label class="lighter mb-0">4 {{ $t("Modus") }} </label>
    <info-helper class="ml-1" text="MetaUpdateMode"/>
    <tile-button
        ref="tiles"
        :initial-active-tiles="[metaSetUpdateModes[0]]"
        :tiles="metaSetUpdateModes"
        :values="metaSetUpdateModes"
        @trigger="selectMode"
    />
  </div>
    <regex-selector
        v-if="metaConfig.metaSet.id"
        class="col-12"
        @setRegexes="setRegexes"
    />
  <div
      v-if="metaConfig.metaSet.id"
      class="col-12 mt-3"
  >
    <label class="lighter mb-0">5 {{ $t("email") }}</label>
    <input
        v-model="email"
        :class="['form-text form-text-dark w-100', $v.email.$dirty && $v.email.$error ? 'form-group--error' : '',]"
        type="text"
        @input="delayTouch($v.email)"
    />
    <div
        v-if="$v.email.$dirty && !$v.email.email"
        class="form-error mb-2"
    >
      {{ $t("errors.mustBeAValidEmailAdress") }}
    </div>
    <Button
        :deactivated="!this.$v.email.email"
        class="mt-3 icon-rotate-animation make-longer-animation"
        @click="startMetaSetUpdate"
    >{{ $t("XMLUpdateStart") }}
      <icon type="cube"/>
    </Button>
  </div>
  </div>
</template>

<script>
import Button from "../forms/Button";
import Icon from "../Icon";
import TileButton from "@/components/forms/TileButton";
import InfoHelper from "@/components/InfoHelper";
import VuelidateMixin from "@/components/mixins/VuelidateMixin.js";
import {metaDataFileFormats, metaSetUpdateModes, TaskManagerTaskTypes} from "@/enum";
import {email} from "vuelidate/lib/validators";
import MetaDataUpload from "@/components/assemblies/MetaDataUpload.vue";
import MetaSetSelector from "@/components/widgets/meta/MetaSetSelector.vue";
import RegexSelector from "@/components/assemblies/RegexSelector.vue";
export default {
  name: "MetaDataUpdater",
  components: {
    RegexSelector,
    MetaSetSelector,
    TileButton,
    InfoHelper,
    Button,
    Icon,
    MetaDataUpload
  },
  mixins: [VuelidateMixin],
  props: {
    remoteContent: {type: Object, required: true},
    canUpdate: {type: Boolean, default: false}
  },
  data() {
    return {
      /**
       * the columns from the meta file (which is usually a table of some sort)
       * */
      metaDataColumns: [],
      /**
       * Sets the dropzone to loading-state while processing data
       * */
      isLoading: false,
      /**
       * the available file formats = processors for meta-files (currently Windchill Search Res only)
       * */
      metaDataFileFormats: metaDataFileFormats,
      vhubPreProcessorOptions: metaDataFileFormats,

      /**
       * The preprocessor will be set as
       * */
      vhubPreProcessor: "",

      /**
       * the mode to use for either overwriting/extending or reset the meta-data
       * */
      mode: "extend",
      metaSetUpdateModes: metaSetUpdateModes,

      email: this.$store.getters.getUserEmail(this.$store.getters.getCurrentUserId),

      content: {},
      dataProcessor: null,
      error: '',
      files: [],
      regexes: [],
      // this is how the pipeline expects the metaConfig
      metaConfig: {
        partIdentifierColumn: '',
        metaSet: {
          name: null,
          id: null
        }
      }
    };
  },
  validations: {
    email: {
      email,
    },
  },
  methods: {
    setRegexes(regexes) {
      this.regexes = regexes && regexes.length ? regexes.map(item => {
        return {
          id: item.id,
          content: item.content
        }
      }) : [];
    },
    setMetaSet(metaSetName, metaSetId) {
      console.log('setMetaSet', metaSetName, metaSetId);
      this.metaConfig.metaSet.name = metaSetName;
      this.metaConfig.metaSet.id = metaSetId;
    },
    setVhubPreProcessor(vhubPreProcessor) {
      this.vhubPreProcessor = vhubPreProcessor;
    },
    // 1 resume upload-dropzone upload which triggers 2
    startMetaSetUpdate() {
      this.$refs.metaDataUpload.resumeUpload();
    },
    // 2 when file is uploaded, start update of sfx content and
    // 3 start the pipeline task "dataprocessing"
    async fileReceived(assetId, fileName) {
      console.log('fileReceived', assetId, fileName);
      this.$emit('processing')
      await this.updateAssemblyContent(fileName)
          .then(async () => {
            await this.triggerPipelineTask();
            this.isLoading = false;
          });
    },
    async triggerPipelineTask() {
      return await this.$store.dispatch("clientAssetToPipeline", {
        id: this.$route.params.id,
        type: TaskManagerTaskTypes.DATAPROCESSING,
        config: JSON.stringify({
          ...this.content.pipeline,
          sendMails: true,
          email: this.email,
          lang: this.$i18n.locale ? this.$i18n.locale : 'en',
        })
      })
    },
    setMetaDataColumns(data) {
      this.metaDataColumns = data;
    },
    reset() {
      this.metaConfig.partIdentifierColumn = '';
      this.metaConfig.metaSet.id = null;
      this.metaConfig.metaSet.name = null;
    },
    async updateAssemblyContent(clientMetaFile) {
      if(!this.metaConfig.metaSet.id || this.metaConfig.metaSet.id === 999) {
        if(this.$refs.metaSelect) {
          const newMetaSet = await this.$refs.metaSelect.createNewMetaSet();
          this.metaSetId = newMetaSet.id;
          this.metaSetName = newMetaSet.name;
        }
      }
      this.content.pipeline = {
        files: [`${this.$route.params.id}/${clientMetaFile}`],
        sendMails: true,
        email: this.email,
        lang: this.$i18n.locale ? this.$i18n.locale : "en",
        dataProcessor: this.vhubPreProcessor,
        meta: {
          updateMode: this.mode,
          clientMetaFile: clientMetaFile,
          regexes: this.regexes,
          ...this.metaConfig,
        }
      }
      return await this.$store.dispatch("loadAssembly", {
        id: this.$route.params.id
      }).then(async () => {
        return await this.$store.dispatch("updateAssembly", {
          id: this.$route.params.id,
          content: JSON.stringify(this.content),
        });
      });
    },
    selectMode(params) {
      this.mode = params.value;
    },
  }
}
</script>

<style scoped>
.meta-data-updater {
  max-width: 600px;
}
</style>
