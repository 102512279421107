<template>
  <div class="example-view">
    <div class="settings-button d-inline-block" @click="toggleContent">
      {{ showContent ? $t("HideExamples") : $t(buttonText) }}
    </div>
    <div v-if="showContent" class="collapsible-content pt-1 mt-2 border-radius">
      <div class="mt-3 code-example">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ExampleView",
  props: {
    buttonText: {
      type: String,
      default: "ShowFileExamples"
    }
  },
  data() {
    return {
      showContent: false
    }
  },
  methods: {
    toggleContent() {
      this.showContent = !this.showContent;
    }
  }
}
</script>

<style scoped>
h5 {
  font-family: "Inter bold", sans-serif;
  color: #000;
}

.collapsible-content {
  padding: 0 18px;
  background-color: #d0cdcd;
  border: 1px solid #8f8f8f;
}

.collapsible-content pre {
  margin: 0;
  padding: 0;
}
.code-example {
  color: #000;
  b {
    font-family: "Inter bold", serif;
  }
}
</style>