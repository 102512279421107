<template>
  <div>
    <h1>{{ editing ? 'Edit License' : 'Create License' }}</h1>
    <form>
      <div>
        <label for="name">Name</label>
        <input id="name" v-model="form.name" type="text" required />
      </div>
      <div>
        <label for="startDate">Start Date</label>
        <input id="startDate" v-model="form.startDate" type="date" required />
      </div>
      <div>
        <label for="expirationDate">Expiration Date</label>
        <input id="expirationDate" v-model="form.expirationDate" type="date" required />
      </div>
      <div>
        <label for="type">Type</label>
        <select id="type" v-model="form.type" required>
          <option value="viewer">Viewer</option>
          <option value="editor">Editor</option>
        </select>
      </div>
      <div>
        <label for="price">Price</label>
        <input id="price" v-model="form.price" type="number" step="0.01" required />
      </div>
      <div>
        <label for="user">User</label>
        <select id="user" v-model="form.userId">
          <option value="">N/A</option>
          <option v-for="user in users" :key="user.id" :value="user.id">{{ user.name }}</option>
        </select>
      </div>
      <div class="settings-button d-inline-block mr-2" @click.prevent="$emit('submit', form)" type="submit">{{ editing ? 'Save' : 'Create' }}</div>
      <div class="settings-button d-inline-block" @click.prevent="$emit('abort')">Abort</div>
    </form>
  </div>
</template>

<script>
export default {
  name: "LicenseForm",
  props: {
    license: {
      type: Object,
      default: () => ({
        id: null,
        name: '',
        startDate: '',
        expirationDate: '',
        type: 'viewer',
        price: 0,
        userId: ''
      })
    },
    users: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      form: {...this.license},
      editing: !!this.license.id
    };
  },
  methods: {
    submitForm() {
      this.$emit('submit', this.form);
    }
  }
}
</script>

<style scoped lang="scss">
/* Add your styles here */
</style>