<template>
  <div :class="['tree-view-container pb-1']">
    <!--<div @click="loadData"><icon type="redo" /></div>-->
    <!--<input type="number" class="form-text vform-text border-radius mb-1" v-model="sizeInPercentage" placeholder="size in percentage" />-->
    <div class="stuck-on-top row w-100 justify-content-start">
      <div class="dflex ml-2">
        <label :for="'chkshowastiles' + $vnode.key" class="container"
        >{{ $t("showFlat") }}
          <input
              :id="'chkshowastiles' + $vnode.key"
              v-model="showFlat"
              type="checkbox"
          />
          <span class="checkmark"></span>
        </label>
      </div>
      <div class="dflex ml-2">
        <label :for="'showMetaValues' + $vnode.key" class="container"
        >{{ $t("showMetaValues") }}
          <input
              :id="'showMetaValues' + $vnode.key"
              v-model="showMetaValues"
              type="checkbox"
          />
          <span class="checkmark"></span>
        </label>
      </div>
    </div>
    <loading-panel v-if="loading" class="mb-2 ml-2 mr-2" message="pleaseWait"/>
    <div v-if="!showFlat && !loading" class="tree-view special-scrollbar">
      <node
          v-if="$store.getters.getAssemblyItemsChildren(null, projectId)"
          :key="forceReRenderKey"
          :editing="editing"
          :enable-filtering="enableFiltering"
          :filtered="filteredItemsAsKeys"
          :highlight-filter-on="highlightStringArray.length || highlightFilterArray.length"
          :highlighted="$store.getters.getAssemblyItemsInstancesFiltered({sizeInPercentage: sizeInPercentage, listName: listName, asKeys: true, metaFilter: highlightFilterArray, stringFilter: highlightStringArray, stringFilterMode: 'OR' })"
          :item="$store.getters.getAssemblyItemsChildren(null, projectId)[0]"
          :meta-value-filter="showMetaValues ? filterArray : []"
          :mode="mode"
          :project-id="projectId"
          :selected="selected"
          :show-select-box="showSelectBox"
          @preview="preview"
          @setEditing="setEditing"
          @updated="$emit('updated')"
      />
    </div>
    <template v-else-if="!loading">
      <div class="ml-2 mr-2">
        <div class="row no-gutters mb-2">
          <div class="col">
            <label class="vform-label white">start</label>
            <input @input="(evt) => {setVal('offset', parseInt(evt.target.value))}" :value="offset" :max="filteredItems.length" :min="0" class="form-text form-text-dark" type="number"/>
          </div>
          <div class="col">
            <label class="vform-label white">search</label>
            <input @input="(evt) => {setVal('searchVal', evt.target.value)}" :value="searchVal" :placeholder="$t('name')" class="form-text form-text-dark" type="text"/>
          </div>
        </div>
        <div v-if="offset < filteredItems.length" class="clickable" @click="goUp">
          <icon type="angle-up"/>
        </div>
        {{ offset + 1 }} - {{ getEndNumber }} / {{ filteredItems.length }} {{ $t('items') }}

        <node
            v-for="(item, index2) in filteredItems"
            v-if="(index2 <= offset + limit) && (index2 >= offset)"
            :key="forceReRenderKey + item.id"
            :editing="editing"
            :enable-filtering="enableFiltering"
            :highlighted="$store.getters.getAssemblyItemsInstancesFiltered({sizeInPercentage: sizeInPercentage, listName: listName, asKeys: true, metaFilter: highlightFilterArray, stringFilter: highlightStringArray, stringFilterMode: 'OR' })"
            :is-hierarchy="false"
            :item="item"
            :meta-value-filter="showMetaValues ? filterArray : []"
            :mode="mode"
            :project-id="projectId"
            :selected="selected"
            :show-select-box="showSelectBox"
            @preview="preview"
            @setEditing="setEditing"
            @updated="$emit('updated')"
        />
        <div v-if="(limit + offset) < filteredItems.length" class="clickable" @click="offset = offset + limit + 1">
          <icon type="angle-down"/>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import {mapState} from 'vuex';
import Node from "./Node";
import LoadingPanel from "../LoadingPanel";
import Icon from "../Icon";
import {mapGetters} from "vuex";

export default {
  name: "TreeView",
  components: {
    Node,
    LoadingPanel,
    Icon
  },
  props: {
    projectId: {type: String, required: true,},
    stringArray: {
      type: Array, default: () => {
        return []
      }
    },
    filterArray: {
      default: () => {
        return []
      }
    },
    highlightStringArray: {
      type: Array, default: () => {
        return []
      }
    },
    highlightFilterArray: {
      default: () => {
        return []
      }
    },
    enableFiltering: {type: Boolean, default: true},
    showSelectBox: {type: Boolean, default: false},
    mode: {type: String, default: 'include'},
    hasWriteAccess: {type: Boolean, default: true}
  },
  data() {
    return {
      editing: "",
      selected: {},
      listName: "",
      openItems: [],
      showFlat: true,
      sizeInPercentage: null,
      isExclude: false,
      showMetaValues: false,
      forceReRenderKey: 0,
      limit: 20,
      offset: 0,
      loading: false,
      localFilteredItems: [],
      searchVal: "",
      timeout: null
    };
  },
  computed: {
    ...mapGetters(['getAssemblyItemsInstancesFiltered']),
    filteredItems() {
      const f = this.getAssemblyItemsInstancesFiltered({
        sizeInPercentage: this.sizeInPercentage,
        listName: this.listName,
        metaFilter: this.filterArray,
        stringFilter: this.stringArray,
        stringFilterMode: 'OR'
      });
      if (this.searchVal) {
        return f.filter(item => {
          return item.name.includes(this.searchVal)
        });
      } else {
        return f;
      }
    },
    filteredItemsAsKeys() {
      return this.getAssemblyItemsInstancesFiltered({
        sizeInPercentage: this.sizeInPercentage,
        listName: this.listName,
        metaFilter: this.filterArray,
        asKeys: true,
        stringFilter: this.stringArray,
        stringFilterMode: 'OR'
      });
    },
    ...mapState({
      /**
       * whether the user is still loading
       * */
      lists: state => state.dynamicStore.AssemblyItemsInstanceLists,
    }),
    rootNode() {
      if (this.lists && this.lists[this.listName]) {
        const root = this.lists[this.listName].filter(item => {
          return item.parentId === null
        });
        if (root.length) {
          return root[0];
        }
      }
      return {};
    },
    getEndNumber() {
      return this.offset + 1 + this.limit < this.filteredItems.length ? this.offset + 1 + this.limit : this.filteredItems.length;
    }
  },
  watch: {
    offset(val) {
      if (val > this.filteredItems.length - this.limit) {
        this.offset = this.filteredItems.length - this.limit;
        if(this.offset < 0) {
          this.offset = 0;
        }
      }
    },
    searchVal() {
      this.offset = 0;
    },
    mode(val) {
      this.isExclude = val === 'exclude';
    },
    filteredItems() {
      console.log('changed filtered items')
    }
  },
  beforeMount() {
    this.loadData();
  },
  methods: {
    setVal(name, val) {
      if(this.timeout) {
        clearTimeout(this.timeout);
      }
      const $this = this;
      setTimeout(() => {
        $this[name] = val;
      }, 200);
    },
    goUp() {
      this.offset = this.offset - this.limit - 1;
      if (this.offset < 0) {
        this.offset = 0;
      }
    },
    setEditing(id) {
      this.editing = id;
    },
    preview(item) {
      this.$emit('setPreviewId', item);
      this.selected = item;
    },
    loadData() {
      this.loading = true;
      this.listName = this.projectId;
      this.$store.dispatch('loadAssemblyItemsInstances', {
        id: this.projectId,
        listName: this.listName,
        include: 'squashedMeta',
        filter: 'type in model node',
        limit: 0
      }).then(() => {
        this.loading = false;
        try {
          this.preview(this.$store.getters.getAssemblyItemsChildren(null, this.projectId)[0]);
        } catch {
          // do nothing
        }
      })
    },
  }
}
</script>

<style lang="scss" scoped>
.tree-view-container {
  position: relative;
  padding-top: 70px;
  background-color: var(--vform-editor-ui-secondary-color);

}

.tree-view {
  height: 80vh;
  overflow-Y: scroll;
  padding-right: 15px;
  padding-left: 5px;
  background-color: rgba(0, 0, 0, 0.2);
}

.stuck-on-top {
  position: absolute;
  top: 15px;
  left: 15px;
}
.vform-label {
  margin-bottom: 0;
}
</style>