import Swagger from 'swagger-client';
const random = Math.floor(Math.random() * 10000);
//Swagger.http.withCredentials = true;
/**
 * Get the serve API client
 * @param getters
 * @param dispatch
 * @returns {Promise<{}>}
 * @private
 */
const clientApi = async (getters, dispatch) => {
    if (!getters.getClient.apis) {
        await dispatch('loadClient');
    }
    return getters.getClient.apis;
};

/**
 * Returns storage API client
 * @param getters
 * @param dispatch
 * @returns {Promise<{}>}
 * @private
 */
const storageApi = async(getters, dispatch) => {
    if (!getters.getStorageClient.apis) {
        await dispatch('loadStorageClient');
    }
    return getters.getStorageClient.apis;
};

const feedsApi = async(getters, dispatch) => {
    if (!getters.getFeedsClient.apis) {
        await dispatch('loadFeedsClient');
    }
    return getters.getFeedsClient.apis;
};

const getBody = (response) => {
    if(response.body && typeof(response.body) === 'object') {
        response.body.headers = function() {return response.headers ? response.headers : null;};
    }
    return response.body;
};
const getHeaders = (response) => response.headers;

const datafilterMicroService = "https://sfx-vhub-datafilter.azurewebsites.net/";

const state = {
    client: {},
    storageClient: {},
    cache: {},
    version: process.env.VUE_APP_VERSION,
    connection: 'none',
    timeout: null,
    clientId: null,
};



const getters = {
    getClient() {
        return state.client;
    },
    getStorageClient() {
        return state.storageClient;
    },
    getFeedsClient() {
        return state.feedsClient;
    },
    appVersion() {
        return state.version;
    },
    getConfig() {
        return state.config;
    },
    getCache() {
        return state.cache;
    },
    getClientId() {
      return state.clientId;
    },
    getConnectStatus() {
        return state.connection;
    },
    async getClientURL() {
        return await fetch(process.env.VUE_APP_CONFIG)
            .then(r => r.json())
            .then(config => {
                return config.sfx.project.webapp.apiHost + '/api/v2/';
            });
    },
};

const mutations = {
    setClientMutation(state, data) {
        state.client = data;
    },
    setStorageClientMutation(state, data) {
        state.storageClient = data;
    },
    setFeedsClientMutation(state, data) {
        state.feedsClient = data;
    },
    setConfigMutation(state, data) {
        state.config = data;
    },
    setClientId(state, data) {
      state.clientId = data;
    },
    setCache(state, key, value) {
        state.cache[key] = value;
    },
    setConnectStatus(state, status) {
        state.connection = status;
    },

};

const actions = {
    loadClient: async ({commit, getters, dispatch}, counter = 0) => {
        let t = setTimeout(() => {commit('setConnectStatus', 'waiting')}, 2000);
        return fetch(process.env.VUE_APP_CONFIG)
            .then(r => r.json())
            .then(config => {
                commit('setConfigMutation', config);
                const {apiHost, apiPath, clientId} = config.sfx.project.webapp;
                commit('setClientId', clientId);
                return Swagger(apiHost + '/api/v2/api-docs/server.yaml?random=' + random, {
                    requestInterceptor(req) {
                        const authToken = getters.getAuthToken;
                        if(authToken) {
                            req.headers['Authorization'] = 'Bearer ' + authToken;
                        }
                        const lastPart = req.url.split("/").pop();
                        if(lastPart.includes("?")) {
                            req.url = req.url + "&";
                        } else {
                            req.url = req.url + "?";
                        }
                        req.url = req.url + "random=" +random;
                        if(apiPath && !req.url.includes(apiPath)) {
                            req.url = req.url.replace('/api/v2', apiPath + '/api/v2');
                        }
                        return req;
                    },
                    responseInterceptor(res) {
                        if(!res.ok) {
                            res.errors = res.body;
                        }
                        return res;
                    }
                })
            })
            .then(client => {
                clearTimeout(t);
                commit('setConnectStatus', 'none')
                commit('setClientMutation', client);
            }).catch(async e => {
                console.log(e);
                clearTimeout(t);
                commit('setConnectStatus', 'error');
                if(counter < 60) {
                    setTimeout(async () => {
                        await dispatch('loadClient', counter + 1);
                    }, 10000);
                }
                throw new Error('Couldn\'t connect to server');
            });
    },
    loadStorageClient: ({commit, getters}) => {
        return fetch(process.env.VUE_APP_CONFIG)
            .then(r => r.json())
            .then(config => {
                commit('setConfigMutation', config);
                const {storageHost, storagePath} = config.sfx.project.webapp;
                return Swagger(storageHost + '/api/v2/api-docs/storage.yaml?random='+random, {
                    requestInterceptor(req) {
                        req.headers['Authorization'] = 'Bearer ' + getters.authToken;
                        const lastPart = req.url.split("/").pop();
                        if(lastPart.includes("?")) {
                            req.url = req.url + "&";
                        } else {
                            req.url = req.url + "?";
                        }
                        req.url = req.url + "random=" +random;
                        if(storagePath && !req.url.includes(storagePath)) {
                            req.url = req.url.replace('/api/v2', storagePath + '/api/v2');
                        }
                        return req;
                    },
                    responseInterceptor(res) {
                        if(!res.ok) {
                            res.errors = res.body;
                        }
                        return res;
                    }
                })
            })
            .then(client => {
                commit('setStorageClientMutation', client);
            }).catch(() => {
                throw new Error('Couldn\'t connect to server');
            });
    },
    loadFeedsClient:  ({commit, getters}) => {
        return fetch(process.env.VUE_APP_CONFIG)
            .then(r => r.json())
            .then(config => {
                const {feedHost, feedPath} = config.sfx.project.webapp;
                return Swagger(feedHost + '/api/v2/api-docs/feeds.yaml', {
                    requestInterceptor(req) {
                        req.headers['Authorization'] = 'Bearer ' + getters.authToken;
                        if(feedPath && !req.url.includes(feedPath)) {
                            req.url = req.url.replace('/api/v2', feedPath + '/api/v2');
                        }
                        return req;
                    },
                    responseInterceptor(res) {
                        if(!res.ok) {
                            res.errors = res.body;
                        }
                        return res;
                    }
                })
            }).then(client => {
                //console.log(client);
                commit('setFeedsClientMutation', client);
        })
    },
    /***
     * @returns
     *  - in case of an error: a translation string to be used as error message
     * */
    /*clientAuthenticate: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Security.authenticate({}, {requestBody: args}))
            .then(getBody)
            .catch(e => {
                const res = JSON.parse(JSON.stringify(e));
                if(res.response.body.data && res.response.body.data.blocked) {
                    throw new Error('userIsBlocked');
                } else {
                    throw new Error('userPassOrNameIsWrong');
                }
            });
    },*/
    /*clientFetchAuthUrl: ({dispatch, getters}) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Security.getIdentityProviderUrl())
            .then(getBody)
            .catch(e => {
                console.log(e);
                // todo: display error
            });
    },*/
    /*clientAuthenticateMSAL: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.IdentityProvider.identityProviderLogin({}, {requestBody: args}))
            .then(getBody)
            .catch(e => {
                console.log(e);
                const res = JSON.parse(JSON.stringify(e));
                if(res.response.body.data && res.response.body.data.blocked) {
                    throw new Error('userIsBlocked');
                } else {
                    throw new Error('userPassOrNameIsWrong');
                }
            });
    },*/
    // todo: SCRUM-44
    clientAuthLegacy: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(async (apis) => {
                return apis.Security.authenticate({}, {requestBody: args})
            })
            .then(getBody)
            .catch(e => {
                const res = JSON.parse(JSON.stringify(e));
                if(res.response.body.data && res.response.body.data.blocked) {
                    throw new Error('userIsBlocked');
                } else {
                    throw new Error('userPassOrNameIsWrong');
                }
            });
    },
    clientAuth: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then((apis) => {
                args.clientId = getters.getClientId;
                return apis.Security.authenticateUser({}, {requestBody: args})
            })
            .then(getBody)
    },
    clientNewAuthToken: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then((apis) => {
                args.clientId = getters.getClientId;
                return apis.Security.authenticateRefresh({}, {requestBody: args})
            })
            .then(getBody)
            .catch(e => {
                const res = JSON.parse(JSON.stringify(e));
                if(res.response.body.data && res.response.body.data.blocked) {
                    throw new Error('userIsBlocked');
                } else {
                    throw new Error('userPassOrNameIsWrong');
                }
            });
    },
    commitCheckout: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Form.performFormCheckout({id: args.id}, {requestBody: args.args}))
            .then(getBody);
    },
    clientGetOrganization: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Organization.getOrganization({id: args.id}, {requestBody: args}))
            .then(getBody);
    },
    clientGetCurrentUser: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.User.getCurrentUser(args))
            .then(getBody);
    },
    clientLoadUser: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.User.getUser(args))
            .then(getBody);
    },
    clientSaveUser: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.User.updateUser({id: args.id}, {requestBody: args}))
            .then(getBody);
    },

    clientLoadTags: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Tag.getTags(args))
            .then(getBody);
    },
    clientCreateTag: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Tag.createTag({}, {requestBody: args}))
            .then(getBody);
    },
    clientDeleteTag: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Tag.deleteTag({id: args.id}))
            .then(getBody);
    },
    clientUpdateTag: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Tag.updateTag({id: args.id}, {requestBody: args}))
            .then(getBody);
    },
    clientAddProjectTags: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Project.addProjectTags({id: args.id}, {requestBody: args.args}))
            .then(getBody);
    },
    clientRemoveProjectTags: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Project.removeProjectTags({id: args.id}, {requestBody: args.args}))
            .then(getBody);
    },
    clientLoadProjectTags: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Project.getProjectTags(args))
            .then(getBody);
    },
    clientLoadProjectTeams: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Project.getProjectTeams(args))
            .then(getBody);
    },
    clientLoadDataSetTeams: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.DataSet.getDataSetTeams(args))
            .then(getBody);
    },
    clientLoadMetaSetTeams: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.MetaSet.getMetaSetTeams(args))
            .then(getBody);
    },
    clientLoadAssetTeams: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Asset.getAssetTeams(args))
            .then(getBody);
    },
    /**
    * Loads all versions of a given asset
    * **/
    clientLoadAssetVersions: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Asset.getAssetVersions(args))
            .then(getBody);
    },
    clientGetAssemblyProjectInstancesCaching: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Project.getCacheStatusBulk({}, {requestBody: args}))
            .then(getBody);
    },
    clientTriggerAssemblyProjectInstancesCaching: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Project.generateCache(args))
            .then(getBody);
    },
    clientDeleteAssemblyProjectInstancesCaching: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Project.deleteCache({id: args.id}))
            .then(getBody);
    },
    /***
    * Required:
    * tags - UUID a identifier for the version
    * items - Array an array with asset id's
    * */
    clientCreateAssetVersion: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Asset.createAssetVersion({tag: args.tag}, {requestBody: {items: args.items, mode: args.mode}}))
            .then(getBody);
    },
    clientRestoreAssetVersion: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Asset.restoreAssetVersion({tag: args.tag}))
            .then(getBody);
    },
    clientDeleteAssetVersion: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Asset.deleteAssetVersion({tag: args.tag}))
            .then(getBody);
    },
    clientLoadProjectVersions: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Project.getProjectVersions(args))
            .then(getBody);
    },
    /***
    * Required:
    * tags - UUID a identifier for the version
    * items - Array an array with asset id's
    * */
    clientCreateProjectVersion: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Project.createProjectVersion({tag: args.tag}, {requestBody: {items: args.items, mode: args.mode}}))
            .then(getBody);
    },
    clientRestoreProjectVersion: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Project.restoreProjectVersion({tag: args.tag}))
            .then(getBody);
    },
    clientDeleteProjectVersion: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Project.deleteProjectVersion({tag: args.tag}))
            .then(getBody);
    },
    clientAddAssetTags: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Asset.addAssetTags({id: args.id}, {requestBody: args.args}))
            .then(getBody);
    },
    clientRemoveAssetTags: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Asset.removeAssetTags({id: args.id}, {requestBody: args.args}))
            .then(getBody);
    },
    clientLoadAssetTags: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Asset.getAssetTags(args))
            .then(getBody);
    },

    clientLoadPermissions: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Permission.getPermissions(args))
            .then(getBody);
    },
    clientLoadPermission: async ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Permission.getPermission(args))
            .then(getBody);
    },
    clientSavePermission: async ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Permission.updatePermission({id: args.id}, {requestBody: args}))
            .then(getBody);
    },
    clientCreatePermission: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Permission.createPermission({}, {requestBody: args}))
            .then(getBody);
    },
    clientLoadRolePermissions: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Role.getRolePermissions(args))
            .then(getBody);
    },
    clientAddRolePermissions: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Role.addRolePermissions({id: args.id}, {requestBody: args.args}))
            .then(getBody);
    },
    clientRemoveRolePermissions: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Role.removeRolePermissions({id: args.id}, {requestBody: args.args}))
            .then(getBody);
    },
    clientLoadLicenses: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.License.getLicenses(args))
            .then(getBody);
    },
    clientLoadLicense: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.License.getLicense({id: args.id}))
            .then(getBody);
    },
    clientUpdateLicense: async ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.License.updateLicense({id: args.id}, {requestBody: args}))
            .then(getBody);
    },
    clientCreateLicense: async ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.License.createLicense({}, {requestBody: args}))
            .then(getBody);
    },
    clientDeleteLicense: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.License.deleteLicenses({}, {requestBody: args}))
            .then(getBody);
    },
    clientLoadRoles: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Role.getRoles(args))
            .then(getBody);
    },
    clientLoadRole: async ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Role.getRole(args))
            .then(getBody);
    },
    clientSaveRole: async ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Role.updateRole({id: args.id}, {requestBody: args}))
            .then(getBody);
    },
    clientCreateRole: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Role.createRole({}, {requestBody: args}))
            .then(getBody);
    },
    clientDeleteRole: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Role.deleteRoles({}, {requestBody: args.args}))
            .then(getBody);
    },
    clientDeletePermission: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Permission.deletePermissions({}, {requestBody: args.args}))
            .then(getBody);
    },
    
    clientLoadVirtualMachines: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.VirtualMachine.getVirtualMachines(args))
            .then(getBody);
    },
    clientCreateVirtualMachine: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.VirtualMachine.createVirtualMachine({}, {requestBody: args}))
            .then(getBody);
    },
    clientLoadVirtualMachine: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.VirtualMachine.getVirtualMachine(args))
            .then(getBody);
    },
    clientSaveVirtualMachine: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.VirtualMachine.updateVirtualMachine({id: args.id}, {requestBody: args}))
            .then(getBody);
    },
    clientDeleteVirtualMachine: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.VirtualMachine.deleteVirtualMachines({}, {requestBody: args.args}))
            .then(getBody);
    },
    
    clientLoadTicketingProjects: ({dispatch, getters}) => {
    return clientApi(getters, dispatch)
            .then(apis => apis.Ticketing.getTicketingProjects({}))
            .then(getBody);
    },
    clientLoadTicketingTeams: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Ticketing.getTicketingTeams({projectId: args.projectId}))
            .then(getBody);
    },
    clientLoadContainers: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Ticketing.getContainers(args))
            .then(getBody);
    },
    clientLoadTicketingItems: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Ticketing.getItems(args))
            .then(getBody);
    },
    clientCreateTicket: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Ticketing.createTicket({projectId: args.projectId}, {requestBody: args}))
            .then(getBody);
    },
    /**
    * @params
    *   args
    *       attachmentId - required
    * */
    clientLoadTicketAttachment: ({dispatch, getters}, args) => {
        return storageApi(getters, dispatch)
            .then(apis => apis.Ticketing.getAttachment(args))
            .then(getBody);
    },
    /**
    * @params
    *   args
    *       ticketId - required
    * */
    clientDeleteTicket: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Ticketing.deleteTicket(args))
            .then(getBody);
    },
    clientUpdateTicket: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Ticketing.updateTicket({projectId: args.projectId}, {requestBody: args}))
            .then(getBody);
    },
    
    clientLoadVMLogs: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.VMLog.getVMLogs(args))
            .then(getBody);
    },
    clientCreateVMLog: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.VMLog.createVMLog({}, {requestBody: args}))
            .then(getBody);
    },
    clientLoadVMLog: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.VMLog.getVMLog(args))
            .then(getBody);
    },
    clientSaveVMLog: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.VMLog.updateVMLog({id: args.id}, {requestBody: args}))
            .then(getBody);
    },
    clientDeleteVMLog: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.VMLog.deleteVMLogs({}, {requestBody: args.args}))
            .then(getBody);
    },
    
    /*
     * Assets
     */
    clientLoadAssets: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Asset.getAssets(args))
            .then(getBody);
    },
    clientLoadAssetsCount: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Asset.getAssetsCount(args))
            .then(getBody);
    },
    clientLoadAsset: async ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Asset.getAsset(args))
            .then(getBody);
    },
    clientReImportAsset: async ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Asset.reimportAsset(args))
            .then(getBody);
    },

    clientGetPipelineStatus: async ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Pipeline.getTask({id: args.id}))
            .then(getBody);
    },

    clientFinishPipelineTask: async ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Pipeline.finishTask(args))
            .then(getBody);
    },

    /**
     * @param args object
     *      id: assetId
     *      type: "extract" or "reimport"
     * */
    clientAssetToPipeline: async ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Pipeline.startAssetTask({id: args.id}, {requestBody: args}))
            .then(getBody);
    },
    /**
     * @param args object
     *      id: instanceId
     *      type: "extract" (no reimport available at the moment)
     * */
    clientInstanceToPipeline: async ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Pipeline.startInstanceTask({id: args.id}, {requestBody: args}))
            .then(getBody);
    },
    clientLoadAssetQrcode: async ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Asset.getAssetQRCode(args))
            .then(getBody);
    },
    clientSaveAsset: async ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Asset.updateAsset({id: args.id}, {requestBody: args}))
            .then(getBody);
    },
    clientDownloadAsset: async ({dispatch, getters}, args) => {
        if (!getters.getStorageClient.apis) {
            await dispatch('loadStorageClient');
        }
        return getters.getStorageClient.apis.Asset.downloadAssetPart({...args, id: args.id, key: args.key});
    },
    clientCreateInstanceFile: async ({dispatch, getters}, args) => {
      if(!getters.getStorageClient.apis) {
        await dispatch('loadStorageClient');
      }
        return getters.getStorageClient.apis.Assembly.saveInstanceFileToStorage(args);
    },
    clientDownloadInstance: async ({dispatch, getters}, args) => {
        if (!getters.getStorageClient.apis) {
            await dispatch('loadStorageClient');
        }
        return getters.getStorageClient.apis.Instance.downloadInstancePart({id: args.id, key: args.key});
    },
    clientDownloadProject: async ({dispatch, getters}, args) => {
        if (!getters.getStorageClient.apis) {
            await dispatch('loadStorageClient');
        }
        return getters.getStorageClient.apis.Project.downloadProjectPart(args);
    },
    clientDownloadProjectZipPart: async ({dispatch, getters}, args) => {
        // eslint-disable-next-line no-unreachable
        if (!getters.getStorageClient.apis) {
            await dispatch('loadStorageClient');
        }
        return getters.getStorageClient.apis.Project.downloadProjectZipPart({
            id: args.id,
            key: args.key,
            width: args.width ? args.width : null,
            height: args.height ? args.height : null,
            resizeMethod: args.resizeMethod,
            compress: args.compress ? args.compress : null
        }, {requestBody: args});
    },
    clientListStorageUsage: async ({dispatch, getters}, args) => {
        if (!getters.getStorageClient.apis) {
            await dispatch('loadStorageClient');
        }
        return getters.getStorageClient.apis.Storage.listStorageUsage({id: args.id});
    },
    /**
    * requestBody:
    *   {
          "fileName": "string",
          "newFileName": "string",
          "targetId": "string" (optional, only if it is different)
        }
    * */
    clientDuplicateAssetFile: async ({dispatch, getters}, args) => {
        if (!getters.getStorageClient.apis) {
            await dispatch('loadStorageClient');
        }
        return getters.getStorageClient.apis.Asset.duplicateAssetFile({id: args.id}, {requestBody: args});
    },
    /**
    * requestBody:
    *   {
          "fileName": "string",
          "newFileName": "string",
          "targetId": "string" (optional, only if it is different)
        }
    * */
    clientDuplicateProjectFile: async ({dispatch, getters}, args) => {
        if (!getters.getStorageClient.apis) {
            await dispatch('loadStorageClient');
        }
        return getters.getStorageClient.apis.Project.duplicateProjectFile({id: args.id}, {requestBody: args});
    },
    
    /**
    * requestBody:
    *   {
          "jsonFileName": "string",
          "transformFileName": "string"
        }
    * */
    clientCreateDefaultJson: async ({dispatch, getters}, args) => {
        if (!getters.getStorageClient.apis) {
            await dispatch('loadStorageClient');
        }
        return getters.getStorageClient.apis.Asset.createAssetJSON({id: args.id}, {requestBody: args});
    },
    
    /**
    * requestBody:
    *{
          "fileName": "string",
          "fileContent": "string"
     }
    * */
    createAssetTextFile: async ({dispatch, getters}, args) => {
        if (!getters.getStorageClient.apis) {
            await dispatch('loadStorageClient');
        }
        return getters.getStorageClient.apis.Asset.createAssetTextFile({id: args.id}, {requestBody: args});
    },
    /**
    * requestBody:
    *{
          "fileName": "string",
          "fileContent": "string"
     }
    * */
    createProjectTextFile: async ({dispatch, getters}, args) => {
        if (!getters.getStorageClient.apis) {
            await dispatch('loadStorageClient');
        }
        return getters.getStorageClient.apis.Project.createProjectTextFile({id: args.id}, {requestBody: args});
    },
    clientProjectVideoOperation: async ({dispatch, getters}, args) => {
        if (!getters.getStorageClient.apis) {
            await dispatch('loadStorageClient');
        }
        return getters.getStorageClient.apis.Project.videoOperation(args);
    },
    clientProjectGetVideoDuration: async ({dispatch, getters}, args) => {
        if (!getters.getStorageClient.apis) {
            await dispatch('loadStorageClient');
        }
        return getters.getStorageClient.apis.Project.videoDuration(args);
    },
    clientProjectConvertVideoFile: async ({dispatch, getters}, args) => {
        if (!getters.getStorageClient.apis) {
            await dispatch('loadStorageClient');
        }
        return getters.getStorageClient.apis.Project.convertVideo(args);
    },
    clientGetAssetInfo: async ({dispatch, getters}, args) => {
        return storageApi(getters, dispatch)
            .then(apis => apis.Asset.statAssetPart({id: args.id, key: args.key}))
            .then(getHeaders)
    },
    clientLoadCombinedAssetPart:  async ({dispatch, getters}, args) => {
        return storageApi(getters, dispatch)
            .then(apis => apis.Asset.combineAssetPart({id: args.id}, {requestBody: args}))
            .then(getBody)
    },
    clientUpdateAssetMeta: async ({dispatch, getters}, args) => {
        return storageApi(getters, dispatch)
            .then(apis => apis.Asset.updateAssetMeta({id: args.id, key: args.key}, {requestBody: args.meta}))
            .then(getHeaders)
    },
    clientCreateAssemblyInstanceFile: async ({dispatch, getters}, args) => {
        return storageApi(getters, dispatch)
            .then(apis => apis.Assembly.saveInstanceFileToStorage(args))
            .then(getHeaders)
    },
    clientUpdateProjectMeta: async ({dispatch, getters}, args) => {
        return storageApi(getters, dispatch)
            .then(apis => apis.Project.updateProjectMeta({id: args.id, key: args.key}, {requestBody: args.meta}))
            .then(getHeaders)
    },
    clientGetProjectInfo: async ({dispatch, getters}, args) => {
        return storageApi(getters, dispatch)
            .then(apis => apis.Project.statProjectPart({id: args.id, key: args.key}))
            .then(getHeaders)
    },
    clientListProjectParts: async ({dispatch, getters}, args) => {
        return storageApi(getters, dispatch)
            .then(apis => apis.Project.listProjectParts({id: args.id}))
            .then(getBody)
    },
    clientListAssetParts: async ({dispatch, getters}, args) => {
        return storageApi(getters, dispatch)
            .then(apis => apis.Asset.listAssetParts(args))
            .then(getBody)
    },
    clientListInstanceParts: async ({dispatch, getters}, args) => {
        return storageApi(getters, dispatch)
            .then(apis => apis.Instance.listInstanceParts({id: args.id}))
            .then(getBody)
    },
    clientDeleteProjectPart: async ({dispatch, getters}, args) => {
        return storageApi(getters, dispatch)
            .then(apis => apis.Project.deleteProjectPart({id: args.id, key: args.key}))
            .then(getBody)
    },
    clientDeleteAssetPart: async ({dispatch, getters}, args) => {
        return storageApi(getters, dispatch)
            .then(apis => apis.Asset.deleteAssetPart({id: args.id, key: args.key}))
            .then(getBody)
    },
    clientDeleteInstancePart: async ({dispatch, getters}, args) => {
        return storageApi(getters, dispatch)
            .then(apis => apis.Instance.deleteInstancePart({id: args.id, key: args.key}))
            .then(getBody)
    },

    clientGetInstanceInfo: async ({dispatch, getters}, args) => {
        return storageApi(getters, dispatch)
            .then(apis => apis.Instance.statInstancePart({id: args.id, key: args.key}))
            .then(getHeaders)
    },
    clientCreateProjectAsset: async ({dispatch, getters}, args) => {
        return storageApi(getters, dispatch)
            .then(apis => apis.Project.createProjectAsset({id: args.id}, {requestBody: args}))
            .then(getBody);
    },
    clientPublishToMsGuide: async ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Project.pushToMSGuides({id: args.id}, {requestBody: args}))
            .then(getBody);
    },
    clientGetMsGuidesFolders: async ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Project.getMsGuidesFolder(args))
            .then(getBody);
    },
    clientPublishVformToApp: async ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Project.publishvform(args))
            .then(getBody);
    },
    clientUnpublishVformFromApp: async ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Project.unpublishvform(args))
            .then(getBody);
    },
    getProjectConnections: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Project.getProjecConnections(args))
            .then(getBody);
    },
    getProjectInstanceConnections: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Project.getProjecInstanceConnections(args))
            .then(getBody);
    },
    createProjectInstanceConnections: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Project.createProjectInstanceConnections({id: args.id}, {requestBody: args.args}))
            .then(getBody);
    },
    deleteProjectInstanceConnections: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Project.deleteProjectInstanceConnections({id: args.id}, {requestBody: args.args}))
            .then(getBody);
    },
    clientDeleteProjectInstances: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Project.deleteInstances({id: args.id}, {requestBody: args.args}))
            .then(getBody);
    },
    clientLoadAssetFBXFromStorage: async ({dispatch, getters}, args) => {
        if (!getters.getStorageClient.apis) {
            await dispatch('loadStorageClient');
        }

        let url = getters.getStorageClient.url.replace('api-docs/storage.yaml', '');
        return url + '/assets/' + args.id + '/lod0.fbx';
    },

    /*
    * Projects
    * */
    clientLoadProjects: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Project.getProjects(args ))
            .then(getBody);
    },
    clientLoadProject: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Project.getProject(args))
            .then(getBody);
    },
    clientLoadProjectMetaValues: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Project.getProjectMetaValues(args))
            .then(getBody);
    },
    clientCreateProject: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Project.createProject({}, {requestBody: args}))
            .then(getBody);
    },
    clientDeleteProjects: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Project.deleteProjects({}, {requestBody: args}))
            .then(getBody);
    },
    clientCreateAsset: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Asset.createAsset({}, {requestBody: args}))
            .then(getBody);
    },
    clientDeleteAsset: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Asset.deleteAssets({}, {requestBody: args}))
            .then(getBody);
    },
    clientDeleteAssets: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Asset.deleteAssets({}, {requestBody: args}))
            .then(getBody);
    },
    clientCreateService: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Service.createService({}, {requestBody: args}))
            .then(getBody);
    },
    clientLoadServices: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Service.getServices(args))
            .then(getBody);
    },
    clientLoadService: async ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Service.getService(args))
            .then(getBody);
    },
    clientSaveService: async ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Service.updateService({id: args.id}, {requestBody: args}))
            .then(getBody);
    },
    clientDeleteServices: async ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Service.deleteServices({}, {requestBody: args.args}))
            .then(getBody);
    },
    clientGetAccessibleServices: async ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Service.getAccessibleServices({id: args.id, filter: 'filter=permissions.relation.state eq 1'}))
            .then(getBody);
    },
    clientAddServiceAccess: async ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Service.addServiceAccess({id: args.id, cid: args.cid}))
            .then(getBody);
    },
    clientRemoveServiceAccess: async ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Service.removeServiceAccess({id: args.id, cid: args.cid}))
            .then(getBody);
    },
    clientCreateDataset: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.DataSet.createDataSet({}, {requestBody: args}))
            .then(getBody);
    },
    clientDeleteDataset: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.DataSet.deleteDataSets({}, {requestBody: args}))
            .then(getBody);
    },
    clientUpdateProjectInstance: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Project.updateInstance({id: args.id, cid: args.cid}, {requestBody: args}))
            .then(getBody);
    },
    clientLoadProjectInstance: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Project.getInstance(args))
            .then(getBody);
    },
    clientLoadProjectInstances: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Project.getInstances(args))
            .then(getBody);
    },
    clientGetCrossProjectInstances: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Project.getCrossProjectInstances(args))
            .then(getBody);
    },
    clientLoadInstance: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Project.getCrossProjectInstances({filter: 'id eq ' + args.id}))
            .then(getBody);
    },
    clientCreateProjectInstances: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Project.createInstances({id: args.id}, {requestBody: args.args}))
            .then(getBody);
    },
    getProjectInstanceMetaValues: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Project.getProjectInstanceMetaValues(args))
            .then(getBody);
    },

    clientLoadProjectAssets: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Project.getProjectAssets(args))
            .then(getBody);
    },
    clientAddProjectAsset: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Project.addProjectAsset({id: args.id}, {requestBody: args.args}))
            .then(getBody);
    },
    clientRemoveProjectAsset: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Project.removeProjectAssets({id: args.id}, {requestBody: args.args}))
            .then(getBody);
    },
    clientSaveProject: async ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Project.updateProject({id: args.id}, {requestBody: args}))
            .then(getBody);
    },
    clientSaveProjectMetaSetValues: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Project.setProjectMetaValues({id: args.id}, {requestBody: args.values}))
            .then(getBody);
    },
    clientUnsetProjectMetaSetValues: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Project.unsetProjectMetaValue({id: args.id}, {requestBody: args.values}))
            .then(getBody);
    },
    clientSaveAssetMetaSetValues: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Asset.setAssetMetaValues({id: args.id}, {requestBody: args.values}))
            .then(getBody);
    },
    clientUnsetAssetMetaSetValues: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Asset.unsetAssetMetaValues({id: args.id}, {requestBody: args.values}))
            .then(getBody);
    },
    clientSaveOrganizationMetaSetValues: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Organization.setOrganizationMetaValues({id: args.id}, {requestBody: args.values}))
            .then(getBody);
    },

    clientSaveInstanceMetaSetValues: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Project.setProjectInstanceMetaValues({id: args.id, cid: args.cid}, {requestBody: args.values}))
            .then(getBody);
    },

    clientUnsetInstanceMetaSetValues: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Project.unsetProjectInstanceMetaValue({id: args.id, cid: args.cid}, {requestBody: args.values}))
            .then(getBody);
    },
    clientAddAssetsToMetaSet: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.MetaSet.addAssetsToMetaSet({id: args.id}, {requestBody: args.values}))
            .then(getBody);
    },
    clientRemoveAssetsToMetaSet: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.MetaSet.removeAssetsFromMetaSet({id: args.id}, {requestBody: args.values}))
            .then(getBody);
    },
    clientLoadDatasetAsCsv: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.DataSet.getCsvDataSet({id: args.id}))
            .then(result => {
                return result.data;
            });
    },
    clientLoadDatasets: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.DataSet.getDataSets(args))
            .then(getBody);
    },
    clientSaveDataset: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.DataSet.updateDataSet({id: args.id}, {requestBody: args}))
            .then(getBody);
    },
    clientLoadAggregatedDatasetRows: async ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.DataSet.getAggregatedDataSetRows(args))
            .then(getBody);
    },
    clientLoadDatasetRows: async ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.DataSet.getDataSetRows(args))
            .then(getBody);
    },
    clientDeleteDatasetRow: async ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.DataSet.deleteDataSetRow({id: args.id}, {requestBody: args.args}))
            .then(getBody);
    },
    clientUpdateDatasetRow: ({dispatch, getters}, args) => {
        let id = args.id;
        args.id = args.datarowId;
        delete args.datarowId;

        return clientApi(getters, dispatch)
            .then(apis => apis.DataSet.updateDataSetRow({id: id}, {requestBody: args}))
            .then(getBody);
    },
    clientCreateDatasetRow: ({dispatch, getters}, args) => {
        let id = args.id;
        delete args.id;
        return clientApi(getters, dispatch)
            .then(apis => apis.DataSet.createDataSetRow({id: id}, {requestBody: args}))
            .then(getBody);
    },
    clientCreateDatasetRows: ({dispatch, getters}, args) => {
        const {id, values} = args;
        return clientApi(getters, dispatch)
            .then(apis => apis.DataSet.createDataSetRow({id: id}, {requestBody: values}))
            .then(getBody);
    },

    clientLoadDataset: async ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.DataSet.getDataSet(args))
            .then(getBody);
    },
    clientLoadDatasetRowsAsJson: async ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.DataSet.getJSONDataSet(args))
            .then(getBody);
    },
    clientLoadOrganizationMetaValues: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Organization.getOrganizationMetaValues(args))
            .then(getBody);
    },
    clientLoadOrganizationMetaSets: ({dispatch, getters}, args) => {
        let params = {filter: 'organizationId eq ' + args.id, include: 'teams'};
        if(args.q) {
            params.q = args.q;
        }
        return clientApi(getters, dispatch)
            .then(apis => apis.MetaSet.getMetaSets(params))
            .then(getBody);
    },
    clientLoadOrganizationInstances: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Organization.countOrganizationInstances(args))
            .then(getBody);
    },
    clientUpdateMetaSet: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.MetaSet.updateMetaSet({id: args.id}, {requestBody: args}))
            .then(getBody);
    },
    clientLoadMetaSets: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.MetaSet.getMetaSets(args))
            .then(getBody);
    },
    clientLoadMetaSet: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.MetaSet.getMetaSet(args))
            .then(getBody);
    },
    clientUpsertMetaSet: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.MetaSet.upsertMetaSet({}, {requestBody: args}))
            .then(getBody);
    },
    clientAddProjectMetaSet: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Project.addProjectMetaSet({id: args.id}, {requestBody: args.args}))
            .then(getBody);
    },
    clientRemoveProjectMetaSet: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Project.removeProjectMetaSets({id: args.id}, {requestBody: args.args}))
            .then(getBody);
    },

    clientAddOrganizationMetaSet: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Organization.addOrganizationMetaSets({id: args.id}, {requestBody: args.args}))
            .then(getBody);
    },
    clientRemoveOrganizationMetaSet: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Organization.removeOrganizationMetaSets({id: args.id}, {requestBody: args.args}))
            .then(getBody);
    },
    clientAddAssetMetaSet: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Asset.addAssetMetaSets({id: args.id}, {requestBody: args.args}))
            .then(getBody);
    },
    clientRemoveAssetMetaSet: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Asset.removeAssetMetaSets({id: args.id}, {requestBody: args.args}))
            .then(getBody);
    },
    clientUpdateMetaField: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.MetaSet.updateMetaField({id: args.id, cid: args.cid}, {requestBody: args.args}))
            .then(getBody);
    },
    clientCreateOrUpdateMetaField: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.MetaSet.upsertMetaField({id: args.id, cid: args.cid}, {requestBody: args.args}))
            .then(getBody);
    },
    clientDeleteMetaSet: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.MetaSet.deleteMetaSets({id: args.id}, {requestBody: args.args}))
            .then(getBody);
    },
    clientDeleteMetaField: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.MetaSet.deleteMetaField({id: args.id, cid: args.cid}, {requestBody: args.args}))
            .then(getBody);
    },
    clientLoadMetaField: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.MetaSet.getMetaField({id: args.id, cid: args.cid}))
            .then(getBody);
    },
    clientLoadMetaFields: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.MetaSet.getMetasFields(args))
            .then(getBody);
    },
    clientLoadMetaValues: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.MetaSet.getMetaFieldValues(args))
            .then(getBody);
    },

    clientLoadUsers: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.User.getUsers(args))
            .then(getBody);
    },
    clientLoadOrganizationUsers: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Organization.getOrganizationUsers(args))
            .then(getBody);
    },
    clientSetUserDefaultOrganization: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Organization.setUserDefaultOrganization({id: args.id,  cid: args.cid}, {}))
            .then(getBody);
    },
    clientUnsetUserDefaultOrganization: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Organization.unsetUserDefaultOrganization({id: args.id,  cid: args.cid}, {}))
            .then(getBody);
    },
    clientCreateUser: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.User.createUser({}, {requestBody: args}))
            .then(getBody);
    },
    clientDeleteUser: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.User.deleteUser(args))
            .then(getBody);
    },
    clientGetUserOrganizations: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.User.getUserOrganizations({id: args.id}, {requestBody: args.args}))
            .then(getBody);
    },
    clientLoadOrganizations: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Organization.getOrganizations(args))
            .then(getBody);
    },
    clientLoadOrganization: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Organization.getOrganization(args))
            .then(getBody);
    },
    clientSaveOrganization: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Organization.updateOrganization({id: args.id}, {requestBody: args}))
            .then(getBody);
    },
    clientCreateOrganization: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Organization.createOrganization({}, {requestBody: args}))
            .then(getBody);
    },
    clientDeleteOrganization: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Organization.deleteOrganizations({id: args.id}, {requestBody: args.args}))
            .then(getBody);
    },
    clientAddOrganizationMember: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Organization.addOrganizationUser({id: args.id}, {requestBody: args.args}))
            .then(getBody);
    },
    clientUpdateOrganizationMember: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Organization.updateOrganizationUser({id: args.id, cid: args.cid}, {requestBody: args}))
            .then(getBody);
    },
    clientRemoveOrganizationMember: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Organization.removeOrganizationUser({id: args.id}, {requestBody: args.args}))
            .then(getBody);
    },
    clientLoadMaterials: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Asset.getAssets(args))
            .then(getBody);
    },
    clientLoadMaterial: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Asset.getAsset(args))
            .then(getBody);
    },
    clientSaveMaterial: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Asset.updateAsset({id: args.id}, {requestBody: args}))
            .then(getBody);
    },
    clientLoadTeamMembers: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Team.getTeamMembers(args))
            .then(getBody);
    },
    clientUpdateTeamMember: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Team.updateTeamMember({id: args.id, cid: args.cid}, {requestBody: args}))
            .then(getBody);
    },
    clientAddTeamMember: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Team.addTeamMember({id: args.id, cid: args.cid}, {requestBody: args}))
            .then(getBody);
    },
    clientRemoveTeamMember: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Team.removeTeamMember({id: args.id, cid: args.cid}))
            .then(getBody);
    },
    clientLoadUserOrganizations: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.User.getUserOrganizations(args))
            .then(getBody);
    },
    clientLoadUserTeams: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.User.getUserTeams(args))
            .then(getBody);
    },
    clientLoadTeams: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Team.getTeams(args))
            .then(getBody);
    },
    clientLoadTeam: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Team.getTeam(args))
            .then(getBody);
    },
    clientSaveTeam: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Team.updateTeam({id: args.id}, {requestBody: args}))
            .then(getBody);
    },
    /**
     * @param args
     *      organizationId*
     *      name*
     *      email
     * */
    clientCreateTeam: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Team.createTeam({}, {requestBody: args}))
            .then(getBody);
    },
    /**
     * @param args
     *      id: the team id
     * */
    clientDeleteTeam: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Team.deleteTeam({id: args.id}))
            .then(getBody);
    },
    clientDeleteTeams: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Team.deleteTeams({}, {requestBody: args.args}))
            .then(getBody);
    },
    datafilterMicroServiceGetDataRows: ({getters}, args) => {
        const axios = require('axios');
        const authToken = getters.authToken;

        return axios.post(datafilterMicroService,  JSON.stringify(args), {
            headers: {
                'Authorization': 'Bearer ' + authToken,
                "Content-Type": "application/json"
            }}
        ).then(response => {
            if(response.data && typeof(response.data) === 'object') {
                response.data.headers = function() {return response.headers ? response.headers : null;};
            }
            return response.data;
        });
    },

    clientAddProjectResource: async ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Project.addProjectTeam({id: args.id}, {requestBody: args.params}))
            .then(getBody);
    },
    clientRemoveProjectResource: async ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Project.removeProjectTeam({id: args.id}, {requestBody: args.params}))
            .then(getBody);
    },

    clientAddAssetResource: async ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Asset.addAssetTeam({id: args.id}, {requestBody: args.params}))
            .then(getBody);
    },
    clientRemoveAssetResource: async ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Asset.removeAssetTeam({id: args.id}, {requestBody: args.params}))
            .then(getBody);
    },

    clientAddMetaSetResource: async ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.MetaSet.addMetaSetTeam({id: args.id}, {requestBody: args.params}))
            .then(getBody);
    },
    clientRemoveMetaSetResource: async ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.MetaSet.removeMetaSetTeam({id: args.id}, {requestBody: args.params}))
            .then(getBody);
    },

    clientAddDataSetResource: async ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.DataSet.addDataSetTeam({id: args.id}, {requestBody: args.params}))
            .then(getBody);
    },
    clientRemoveDataSetResource: async ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.DataSet.removeDataSetTeam({id: args.id}, {requestBody: args.params}))
            .then(getBody);
    },

    clientAddInstanceResource: async ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Project.addInstanceTeam({id: args.id}, {requestBody: args.params}))
            .then(getBody);
    },
    clientRemoveInstanceResource: async ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Project.removeInstanceTeam({id: args.id}, {requestBody: args.params}))
            .then(getBody);
    },

    thumbnailMicroService: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Organization.removeOrganizationUser({id: args.id}, {requestBody: args.args}))
            .then(getBody);
    },
    getItemType: async ({dispatch, getters}, args) => {
        const id = args.id;
        const apis = await clientApi(getters, dispatch);
        const instance = await apis.Project.getCrossProjectInstances({filter: `id eq ${id}`});
        if(instance && instance.body && instance.body.length) {
            return 'Instance';
        }
        else {
            const asset = await apis.Asset.getAsset({id: id})
                .catch(e => {console.log(e)});
            if(asset && asset.body && asset.body) {
                return 'Asset';
            }
            else {
                const project = await apis.Project.getProject({id: id});
                if(project && project.body && project.body) {
                    return 'Project';
                }
            }
        }
        return null;
    },
    clientStartFeed: async({dispatch, getters}, args) => {
         return feedsApi(getters, dispatch)
            .then(apis => apis.Feed.startFeed(args))
            .then(getBody);
    },
    clientStopFeed: async({dispatch, getters}, args) => {
         return feedsApi(getters, dispatch)
            .then(apis => apis.Feed.stopFeed(args))
            .then(getBody);
    },
    clientLoadFeedStatus: async({dispatch, getters}, args) => {
         return feedsApi(getters, dispatch)
            .then(apis => apis.Feed.queryFeedStatus(args))
            .then(getBody);
    },
    /**
    * @params
    *   args
    *       cid: the user id (mandatory)
    * */
    clientLoadNotifications: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Notification.getUserNotifications(args))
            .then(getBody);
    },
    // id: notification id
    // cid: userId
    clientDeleteNotifications: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Notification.deleteNotification(args))
            .then(getBody);
    },
    clientListPipelineLogFiles: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Pipeline.getTaskLogFileList({id: args.id, item: args.item}))
            .then(getBody);
    },
    clientGetPipelineLogFile: ({dispatch, getters}, args) => {
        return clientApi(getters, dispatch)
            .then(apis => apis.Pipeline.getTaskRemoteFile({id: args.id, item: args.item, key: args.key}))
            //.then(getBody);
    },
};

export default {
    state,
    getters,
    mutations,
    actions
}