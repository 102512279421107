<template>
<div class="rotlocscale-container p-1 border-radius">
  <div class="rotlocscale-prompt p-2 mb-2 border-radius" style="max-width: 100%;">
    {{ $t('rotLocScaleSetterPrompt') }} 
  </div>
  <div class="posrotscalesetter">
      <div class="d-flex">
        <label>Position</label>
        <input class="form-text form-text-dark" type="number" v-model="pos[0]"/>
        <input class="form-text form-text-dark" type="number" v-model="pos[1]"/>
        <input class="form-text form-text-dark" type="number" v-model="pos[2]"/>
      </div>
      <div class="d-flex">
        <label>Rotation</label>
        <input class="form-text form-text-dark" type="number" v-model="rot[0]"/>
        <input class="form-text form-text-dark" type="number" v-model="rot[1]"/>
        <input class="form-text form-text-dark" type="number" v-model="rot[2]"/>
      </div>

      <div class="d-flex">
        <label>Scale</label>
        <input class="form-text form-text-dark" type="number" v-model="scale[0]"/>
        <input class="form-text form-text-dark" type="number" v-model="scale[1]"/>
        <input class="form-text form-text-dark" type="number" v-model="scale[2]"/>
      </div>

      <div @click="save" class="settings-button d-inline-block switched-colors">Save</div>
      </div>
      <div v-if="invalidFloatsDetected" class="form-error mt-2 p-2 border-radius">
        Invalid numbers detected. Could not save.
      </div>
</div>
</template>

<script>
import { SpecialUuids } from "@/enum";
import { Vector3, Transform, ConvertRootZRtoYL, ConvertRootYLtoZR} from './RotLocScaleFunctions.js';

export default {
  name: "RotLocScaleSetter.vue",
  props: {
    instanceId: {type: String, required: true},
    projectId: {type: String, required: true},
  },
  data() {
    return {
      pos: [0, 0, 0],
      rot: [0, 0, 0],
      scale: [1, 1, 1],
      SpecialUuids,
      invalidFloatsDetected: false,
    }
  },
  async beforeMount() {
    console.log('RotLocScaleSetter.vue instanceId: ' + this.instanceId);
    this.init();
  },
  methods: {
    async init() {
      await this.loadInstanceMetaValues();
      this.convertToUnityCoordinates();
    },
    convertToUnityCoordinates() {
      const transformZR = new Transform(
        new Vector3(parseFloat(this.pos[0]), parseFloat(this.pos[1]), parseFloat(this.pos[2])),
        new Vector3(parseFloat(this.rot[0]), parseFloat(this.rot[1]), parseFloat(this.rot[2])),
        new Vector3(parseFloat(this.scale[0]), parseFloat(this.scale[1]), parseFloat(this.scale[2]))
      );

      const coordinates = ConvertRootZRtoYL(JSON.parse(JSON.stringify(transformZR)));

      this.pos = [coordinates.position.x, coordinates.position.y, coordinates.position.z];
      this.rot = [coordinates.rotation.x, coordinates.rotation.y, coordinates.rotation.z];
      this.scale = [coordinates.scale.x, coordinates.scale.y, coordinates.scale.z];
    },
    retrieve3DSMaxCoordinates() {

      console.log("pos rot and scale: ", this.pos, this.rot, this.scale);
      const transformYL = new Transform(
        new Vector3(parseFloat(this.pos[0]), parseFloat(this.pos[1]), parseFloat(this.pos[2])),
        new Vector3(parseFloat(this.rot[0]), parseFloat(this.rot[1]), parseFloat(this.rot[2])),
        new Vector3(parseFloat(this.scale[0]), parseFloat(this.scale[1]), parseFloat(this.scale[2]))
      );
      return ConvertRootYLtoZR(JSON.parse(JSON.stringify(transformYL)));
    },
    async loadInstanceMetaValues() {
      const metaVals = await this.$store.dispatch('getProjectInstanceMetaValues', {
        id: this.projectId,
        cid: this.instanceId,
      });
      const posMetaValue = metaVals.find(mv => mv.metaFieldId === SpecialUuids.POSITION_METAFIELD);
      this.pos = posMetaValue ? JSON.parse(posMetaValue.value) : [0, 0, 0];
      const rotMetaValue = metaVals.find(mv => mv.metaFieldId === SpecialUuids.ROTATION_METAFIELD);
      this.rot = rotMetaValue ? JSON.parse(rotMetaValue.value) : [0, 0, 0];
      const scaleMetaValue = metaVals.find(mv => mv.metaFieldId === SpecialUuids.SCALE_METAFIELD);
      this.scale = scaleMetaValue ? JSON.parse(scaleMetaValue.value) : [1, 1, 1];
    },
    validateFloatValues() {
      const regex = /^-?(1000000000|[1-9][0-9]{0,8}|0)([.,][0-9]+)?$/;
      return this.rot.every(r => regex.test(r));
    },
    async save() {

      if (!this.validateFloatValues()) {
        console.error('Invalid float values detected. Save operation aborted.');
        this.invalidFloatsDetected = true;
        return;
      }

      this.invalidFloatsDetected = false;

      const coordinates = this.retrieve3DSMaxCoordinates();

      const pos = [coordinates.position.x, coordinates.position.y, coordinates.position.z].map(p => parseFloat(p));
      const rot = [coordinates.rotation.x, coordinates.rotation.y, coordinates.rotation.z].map(r => parseFloat(r));
      const scale = [coordinates.scale.x, coordinates.scale.y, coordinates.scale.z].map(s => parseFloat(s));
      
      await this.$store.dispatch('setInstanceMetaValues', {
        id: this.projectId,
        cid: this.instanceId,
        values: [
          {metaFieldId: SpecialUuids.POSITION_METAFIELD, value: pos},
          {metaFieldId: SpecialUuids.ROTATION_METAFIELD, value: rot},
          {metaFieldId: SpecialUuids.SCALE_METAFIELD, value: scale},
        ]
      });
      this.$emit('updated');
    }
  }
}
</script>

<style scoped lang="scss">
.posrotscalesetter {
  label {
    min-width: 100px;
  }
}
.rotlocscale-container {
  background-color: var(--vform-editor-ui-secondary-color);
}

.rotlocscale-prompt {
  background-color: var(--vform-editor-ui-quinary-color);
}
</style>
