<template>
  <example-view>
      <!-- Example payload generic xml -->
      <div class="mt-3 code-example">
        <h5>{{ $t("ExamplePayload") }} GenericXML</h5>
        <b>Version 1:</b><br/>
        <pre>&lt;root&gt;
  &lt;record&gt;
    &lt;column1&gt;Value1&lt;/column1&gt;
    &lt;column2&gt;Value2&lt;/column2&gt;
    &lt;column3&gt;Value3&lt;/column3&gt;
  &lt;/record&gt;
  &lt;record&gt;
    &lt;column1&gt;Value4&lt;/column1&gt;
    &lt;column2&gt;Value5&lt;/column2&gt;
    &lt;column3&gt;Value6&lt;/column3&gt;
  &lt;/record&gt;
&lt;/root&gt;
            </pre>
        <b>Version 2 (column names in first row):</b><br/>
        <pre>
&lt;root&gt;
  &lt;row&gt;
    &lt;cell&gt;column1&lt;/cell&gt;
    &lt;cell&gt;column2&lt;/cell&gt;
    &lt;cell&gt;column3&lt;/cell&gt;
  &lt;/row&gt;
  &lt;row&gt;
    &lt;cell&gt;Value for column1&lt;/cell&gt;
    &lt;cell&gt;Value for column2&lt;/cell&gt;
    &lt;cell&gt;Value for column3&lt;/cell&gt;
  &lt;/row&gt;
&lt;/root&gt;
</pre>
      </div>
      <!-- Example payload WindchillSearchResult -->
      <div class="mt-3 code-example">
        <h5>{{ $t("ExamplePayload") }} WindchillSearchResult</h5>
        <pre>
&lt;?xml version="1.0" encoding="UTF-8"?&gt;
&lt;SearchResults&gt;
  &lt;TableHeader&gt;
    &lt;ColumnName&gt;Part Name&lt;/ColumnName&gt;
    &lt;ColumnName&gt;Some meta field&lt;/ColumnName&gt;
  &lt;/TableHeader&gt;
  &lt;Object&gt;
    &lt;Attribute&gt;val1&lt;/Attribute&gt;
    &lt;Attribute&gt;val2&lt;/Attribute&gt;
  &lt;/Object&gt;
&lt;/SearchResults&gt;
        </pre>
      </div>
      <!-- Example payload CSV -->
      <div class="mt-3 code-example">
        <h5>{{ $t("ExamplePayload") }} CSV</h5>
        <pre>
PartName;Material
Cube;Iron
Cube;Steel
Cube;Plastic
Cube;Foam
            </pre>
      </div>
  </example-view>
</template>

<script>
import ExampleView from "@/components/ExampleView.vue";
export default {
  name: "ExamplePayloadView",
  components: {
    ExampleView
  },
  data() {
    return {
      showExamples: false
    }
  },
  methods: {
    toggleExamples() {
      this.showExamples = !this.showExamples;
    },
  }
}
</script>

<style scoped>
h5 {
  font-family: "Inter bold", sans-serif;
  color: #000;
}

.collapsible-content {
  padding: 0 18px;
  background-color: #f1f1f1;
}

.collapsible-content pre {
  margin: 0;
  padding: 0;
}
.code-example {
  color: #000;
  b {
    font-family: "Inter bold", serif;
  }
}
</style>