<template>
  <div>
    <example-payload-view />
    <upload-drop-zone
        :ref="dropzoneId"
        class="meta-data-upload mt-2"
        :accepted-file-types="Object.values(metaDataFileFormats).map((item) => {return '.' + item.format;}).join(',')"
        :asset-id="assetId"
        :auto-update-preview="false"
        :auto-upload="false"
        :downloadable="false"
        :image-editable="true"
        :is-loading="isLoading"
        :removable="false"
        asset-type="assembly"
        :form-id="dropzoneId"
        target-type="Asset"
        @data="setColumnNames"
        @fileReceived="fileReceived"
        @queueEmpty="queueEmpty"
    >
      <div class="mt-3">
        {{ $t("uploadMetaFile") }}
      </div>
    </upload-drop-zone>

    <div v-if="error" class="form-error mt-2">
      {{ error }}
    </div>
    <div v-if="metaDataColumns && metaDataColumns.length">
      <label class="lighter mt-3 mb-0">1 {{ $t("PostProcessingForMeta") }}</label><br/>
      <drop-down
          :labels="Object.values(metaDataFileFormats).map((key) => {return $t(key.name)})"
          :values="Object.keys(metaDataFileFormats)"
          :initial-active-value="vhubPreProcessor"
          class="w-100"
          @select="setVhubPreProcessor"
      />
    </div>
    <div v-if="vhubPreProcessor">
      <div class="mt-2">
        <label class="lighter mb-0">2 {{ $t("selectIdentifier") }}</label><br/>
        <drop-down
            :labels="metaDataColumns"
            :values="metaDataColumns"
            class="w-100"
            @select="setPartIdentifierColumn"
        />
      </div>
    </div>
  </div>
</template>

<script>
import UploadDropZone from "@/components/files/UploadDropZone.vue";
import DataProcessor from "@/components/fileProcessors/DataProcessor";
import {decodeArrayBuffer, sleep} from "@/utils";
import {metaDataFileFormats} from "@/enum";
import ExamplePayloadView from "@/components/assemblies/ExamplePayloadView.vue";
import DropDown from "@/components/forms/DropDown.vue";
export default {
  name: "MetaDataUpload",
  components: {DropDown, UploadDropZone, ExamplePayloadView},
  props: {
    assetId: {type: String, required: true},
    dropzoneId: {type: String, default: 'metaDataUpload'}
  },
  data() {
    return {
      fileContent: '',
      metaDataColumns: [],
      metaDataFileName: '',
      isLoading: false,
      metaConfig: {},
      vhubPreProcessor: '',
      error: '',
      dataProcessor: null,
      metaDataFileFormats,
      partIdentifierColumn: null
    };
  },
  watch: {
    vhubPreProcessor: {
      handler: function () {
        this.$emit('setVhubPreProcessor', this.vhubPreProcessor);
      },
    }
  },
  methods: {
    setVhubPreProcessor(value) {
      this.vhubPreProcessor = value;
    },
    setPartIdentifierColumn(value) {
      this.partIdentifierColumn = value;
      this.$emit('partIdentifierColumn', value);
    },
    resumeUpload() {
      this.$refs[this.dropzoneId].resumeUpload();
    },
    setColumnNames(data, file) {
      console.log('setColumnNames', data);
      // retrieves the column names
      this.fileContent = data;
      const {name} = file;
      this.autoSetParser(name);
    },
    fileReceived(assetId, fileName) {
      console.log('fileReceived');
      this.$emit('fileReceived', assetId, fileName);
    },
    queueEmpty: function () {
      this.fileContent = null;
      this.metaConfig = {};
      this.metaDataColumns = [];
      this.vhubPreProcessor = null;
      this.$emit('reset');
    },
    async parseData() {
      console.log('parseData');
      this.error = '';
      this.metaDataColumns = [];
      this.metaConfig = {};
      this.dataProcessor = new DataProcessor(this.vhubPreProcessor);
      await this.dataProcessor.init();
      const file = decodeArrayBuffer(this.fileContent);
      this.metaDataColumns = await this.dataProcessor.getColumnNames(file)
          .catch(() => {
            this.error = this.$t('errors.errorWhileParsingFile');
          });
      this.$emit('metaDataColumns', this.metaDataColumns);
    },
    async autoSetParser(fileName) {
      await sleep(300);
      console.log('autoSetParser', fileName);
      if(fileName.includes('.csv')) {
        console.log('csvParser');
        this.vhubPreProcessor = 'csvParser';
      } else {
        console.log('genericXMLParser');
        this.vhubPreProcessor = 'genericXMLParser';
      }
      if(this.vhubPreProcessor) {
        await this.parseData();
        if(this.error) {
          console.log('error', 'xmlWindchillSearchRes');
          this.vhubPreProcessor = 'xmlWindchillSearchRes';
          await this.parseData();
        }
      }
    },

  }
}
</script>

<style scoped lang="scss">
.meta-data-upload {
  max-width: 400px;
}
</style>