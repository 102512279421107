<template>
<example-view
  button-text="ShowRegexExamples"
>
  <div class="mt-3 code-example">
    <!---<h5>{{ $t("Update a 3D assembly") }}</h5>
    <pre>
      (?&lt;id&gt;[ABC]\d{7})-(?&lt;ignore&gt;\d{3}[A-Z]{1,2})-(?&lt;name&gt;.*)\.(?&lt;index&gt;\d{1,5})
    </pre>
    <div class="lighter">
      Explanation: <br>
      The segments are combined like this: id::name::index<br>
      So the regex transforms the string A1234567-111AB-NAME.10 into A1234567::NAME::10 on both, the source and the target side which let's them then .<br>
      <b>id</b> - The ERP number of the part<br>
      <b>ignore</b> - The part identifier<br>
      <b>name</b> - The name of the part<br>
      the name can also contain JUST the id or JUST the name part
    </div>-->

    <h5 class="mt-3">{{ $t("Applying meta data or rename 3D parts") }}</h5>
    Base string: <b>clientX_projectY_789_rev_C_phase2_v_45</b><br />
    <div class="lighter mb-3">You HAVE to use named groups in order for those regexes to work. The groups will be sorted in alphabetical order.</div>
    <b>Version 1</b> (this will match the version number and the name):
    <pre>
^(?&lt;bGroup&gt;.+?)(?&lt;aGroup&gt;_v_\d+)$

Result:
<b>aGroup:</b> _v_45
<b>bGroup:</b> clientX_projectY_789_rev_C_phase2
<b>Resulting string:</b>_v_45clientX_projectY_789_rev_C_phase2
      <hr class="mb-0" />
    </pre>
      <b>Version 2</b> (if the groups are in a different order for the source data and the target data):
    <pre>
^(?:(?&lt;aGroup&gt;_v_\d+)(?&lt;bGroup&gt;.+)|(?&lt;dGroup&gt;.+?)(?&lt;cGroup&gt;_v_\d+))$

Result (source data: clientX_projectY_789_rev_C_phase2_v_45):
<b>aGroup:</b> (empty)
<b>bGroup:</b> (empty)
<b>cGroup:</b> _v_45
<b>dGroup:</b> clientX_projectY_789_rev_C_phase2
<b>Resulting string:</b> _v_45clientX_projectY_789_rev_C_phase2

Result (target data: _v_45clientX_projectY_789_rev_C_phase2):
<b>aGroup:</b> _v_45
<b>bGroup:</b> clientX_projectY_789_rev_C_phase2
<b>cGroup:</b> (empty)
<b>dGroup:</b> (empty)
<b>Resulting string:</b> _v_45clientX_projectY_789_rev_C_phase2
    </pre>
  </div>
</example-view>
</template>

<script>
import ExampleView from "@/components/ExampleView.vue";
export default {
  name: "ExampleRegex",
  components: {
    ExampleView
  },
}
</script>

<style scoped lang="scss">

</style>