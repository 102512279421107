<template>
  <one-column-sidebar-left
      :id="$route.params.id"
      back-button-tab-selector="sfxbackbutton"
      sub-menu-selector="librarypanel"
      tab-selector="assemblytab"
  >
    <!---Main Info Panel-->
    <info-panel :id="$route.params.id"
                slot="sidebarLeft"
                :created="$store.getters.getAssemblyCreationDate($route.params.id)"
                :downloadable="true"
                :image-editable="true"
                :last-change="$store.getters.getAssemblyChangeDate($route.params.id)"
                :media-type="$store.getters.getAssemblyType(this.$route.params.id)"
                :organization="$store.getters.getAssemblyOrganization($route.params.id)"
                :preview-uri="$store.getters.getAssemblyPreviewUri($route.params.id)"
                preview-id="sfxedit"
                upload-type="Asset"
                class="new-style bright"
    >
      <div v-if="$store.getters.isSuperAdmin" slot="info" class="form-section">
        <div class="lighter uppercase">{{ $t('sourceProjectId') }}</div>
        {{ $store.getters.getAssemblySourceProjectId($route.params.id) }}<br/>
        <div class="mt-2 mb-2">
          <span class="lighter">
            <icon type="folder"/> STORAGE</span><br/>
          <span class="small">{{
              `${$store.getters.getAssemblyOrganization($route.params.id)}/assets/${this.$route.params.id}`
            }}</span>
        </div>
        <router-link v-if="$store.getters.isSuperAdmin || $store.getters.isSFXMember" :to="'/library/3d-data/add-asset-bundle/' + $route.params.id"
                     class="mt-3 darker p-2 border-radius">
          Edit asset bundle
          <icon type="external-link-alt"/>
        </router-link>
      </div>
    </info-panel>
    <!--TAGS-->
    <info-panel
        :id="$route.params.id"
        key="infopanel2"
        slot="sidebarLeft"
        :limit-tags-to-organization="$store.getters.getAssemblyOrganization($route.params.id)"
        :taggable="true"
        :tags="$store.getters.getAssemblyTags($route.params.id)"
        preview-id="sfxedit"
        @addTag="addTag"
        @removeTag="removeTag"
        class="new-style bright"

    />
    <!--TEAMS-->
    <team-attacher
        v-if="$store.getters.getAssemblyOrganization($route.params.id)"
        :id="$route.params.id"
        slot="sidebarLeft"
        :limit-by-organization-id="$store.getters.getAssemblyOrganization($route.params.id)"
        :read-only="!hasWritingAccess"
        :teams="teams"
        class="mt-3 new-style bright"
        store-name="Asset"
        @added="loadAssetTeams"
        @removed="loadAssetTeams"
    />
    <div slot="mainContent">
      <!--TITLE-->
      <name-display-edit-block
          :id="$route.params.id"
          :editable="isNotLocked() && hasWritingAccess"
          :organization-id="$store.getters.getAssemblyOrganization($route.params.id)"
          :name="$store.getters.getAssemblyName($route.params.id)"
          :display-name="$store.getters.getAssemblyDisplayName($route.params.id)"
          icon="assembly"
          store-name="Assembly"
      />
      <!--LOCK-STATUS-->
      <div v-if="!isNotLocked()" class="form-error">
        {{ $t('assemblyIsLocked') }}
      </div>
      <!--DESCRIPTION-->
      <content-description-block
          :id="$route.params.id"
          :editable="isNotLocked() && hasWritingAccess"
          :value="$store.getters.getAssemblyDescription($route.params.id)"
          store-name="Assembly"
      />
      <div class="row">
        <!--STATE-->
        <state-setter
            v-if="isNotLocked() && hasWritingAccess"
            :given-state="$store.getters.getAssemblyState($route.params.id)"
            :target-id="$route.params.id"
            class="col-12 col-md-4 mb-2 mt-2"
            store-name="Assembly"
        />
        <div class="w-100"/>
        <div v-if="hasPipelineError" class="form-error p-3 ml-3">
          {{ $t('pipelineErrorMsg') }}
        </div>
        <div class="w-100" />
        <glb-conversion-trigger
            v-if="!hideGlbForm && !hasGlb && isNotLocked() && hasWritingAccess && !isProcessing && pipelineStatus === 'finished'"
            :asset-id="$route.params.id"
            type="assembly"
            class="col-12 col-md-4 mt-3 mb-3"
            @taskStarted="hideGlbForm = true"
        />
        <div class="w-100" />
        <div class="form-error col-12 col-md-4 ml-3" v-if="!hasGlb && hideGlbForm">
          {{ $t('yourDataGetsConverted') }}
        </div>
      </div>
      <!--IMPORT PANEL-->
      <info-panel
          v-if="hasWritingAccess && $store.getters.getAssemblyContentHasPipelineFile($route.params.id)"
          :collapsible="true"
          :initially-collapsed="pipelineStatus === 'finished' || !hasRootFile"
          info-panel-header="importConfig"
          preview-id="fileProcessing"
          class="new-style bright"
      >
        <div slot="info" class="row p-2">

          <!--IMPORT DIALOG--->
          <div class="col-12 col-md-12 mt-4 pl-4">
            <h2>{{ hasRoot ? $t('updateSfxdata') : $t('importSFX') }}</h2>

            <!--IMPORT FORM-->
            <assembly-import-config
                v-if="content && showPipelineForm()"
                :asset-id="$route.params.id"
                :content="content"
                :has-root="hasRoot"
                :old-content="oldContent"
                :pipeline-status="pipelineStatus"
                :teams="teams"
                :organization-id="$store.getters.getAssemblyOrganization($route.params.id)"
                @isUpdate="isUpdate = true"
                @startingTask="showLoading = true"
                @taskStarted="showProgress = true; showLoading = false; isProcessing = true; loadAssembly();"
            />

            <!--STATUS INFO-->
            <div class="row">
              <div v-if="['processing', 'finished'].includes(pipelineStatus) || isProcessing"
                   class="mt-3 mb-3 col-12 col-md-8">
                <div v-if="(pipelineStatus === 'processing' || isProcessing) && !showLoading && hasRootFile"
                     class="darker border-radius p-2">
                  <div class="mb-2">{{ $t('yourFileIsGettingProcessed') }}</div>
                </div>
                <div v-else-if="pipelineStatus === 'finished' || !hasRootFile" class="bg-bright border-radius p-2">{{
                    $t('yourFileIsFinishedProcessing')
                  }}
                </div>
              </div>
            </div>

            <!--PROGRESS INFORMATION-->
            <div class="row">
              <log-entry-progress
                  v-if="showProgress || ['processing', 'finished'].includes(pipelineStatus) || !hasRootFile"
                  :is-update="isUpdate"
                  :id="$route.params.id"
                  class="col-12 col-md-8 mt-1"/>
            </div>
          </div>
        </div>
      </info-panel>

      <!--LOWER PANELS--->
      <div class="row">
        <!--ATTACH METASETS-->
        <div class="col-12 col-lg-6 no-gutters">
          <info-panel
              v-if="$store.getters.isSuperAdmin"
              :collapsible="true"
              :initially-collapsed="true"
              info-panel-header="sfx add metasets"
              preview-id="metasetattacher"
              class="new-style bright"
          >
            <meta-set-attacher
                slot="info"
                :given-meta-sets="$store.getters.getAssemblyMetaSets($route.params.id)"
                :target-id="$route.params.id"
                store-name="Asset"
                @added="loadAssembly"
                @removed="loadAssembly"
            />
          </info-panel>
        </div>

        <!--META-PANELS-->
        <template v-if="Object.keys($store.getters.getAssemblyMetaSets($route.params.id)).length && hasWritingAccess">
          <meta-panel
              v-for="(item) in $store.getters.getAssemblyMetaSets($route.params.id)"
              :key="item.id"
              :fields="item.metaFields"
              :organization-ids="[$store.getters.getAssemblyOrganization($route.params.id)]"
              :title="'MetaSet: ' + item.description"
              class="col-12 col-lg-6"
              classes="new-style bright"
              icon-left="folder"
              store-name="Assembly"
              type="Assembly"
              @updated="loadAssembly"
          />
        </template>

        <!--VM LOGS-->
        <div v-if="$store.getters.isSuperAdmin" class="col-6">
          <info-panel
              :collapsible="true"
              :initially-collapsed="true"
              info-panel-header="VM-Logs"
              preview-id="vmlogs"
              class="new-style bright"
          >
            <log-viewer
                slot="info"
                :data="$store.getters.getAssemblyVMLogs($route.params.id)"
                @entryDeleted="loadAssembly()"
            />
          </info-panel>
        </div>
        <!--TASKMANAGER-->
        <div v-if="($store.getters.isSuperAdmin || $store.getters.isSFXMember) && dataRow" class="col-6">
          <table class="w-100">
            <tr>
              <th>Taskmanager</th>
            </tr>
            <dataset-row-taskmanager
                v-if="dataRow.data && taskmanagerFields"
                :data-row="dataRow"
                :dataset-id="SpecialUuids['TASKMANAGER_DATASET']"
                :fields="taskmanagerFields"
                :hidden-fields="[0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18]"
                type="Asset"
            />
          </table>
        </div>

        <!--raw code-->
        <div class="col-6">
          <info-panel
              v-if="$store.getters.isSuperAdmin"
              :collapsible="true"
              :initially-collapsed="true"
              info-panel-header="RAW"
              preview-id="codem"
              class="new-style bright"
          >
            <div slot="info">
              <codemirror
                  :value="JSON.stringify($store.getters.getAssemblyContent(this.$route.params.id))"
                  class="col-12"
                  @save="updateContentField"
                  :hide-abort-button="true"
              />
            </div>
          </info-panel>
        </div>
        <div class="col-6">
          <info-panel
              v-if="projectId && ($store.getters.isSuperAdmin || $store.getters.isSFXMember)"
              :collapsible="true"
              :initially-collapsed="true"
              info-panel-header="Cache"
              preview-id="crossproject"
              class="new-style bright">
            <div slot="info">
            <assembly-caching-handler
                class="p-3 assembly-caching-handler mt-2 mb-2 border-radius"
                :project-id="projectId"
            />
            </div>
          </info-panel>
        </div>
        <div class="darker col-5 p-3 ml-3 mr-3 border-radius" v-if="$store.getters.isSFXMember ||$store.getters.isSuperAdmin">
          <div class="settings-button d-inline-block" @click="loadUsage">Load usage</div>
          <div :key="instance.id" v-for="(instance) in instances">
            <router-link :to="'/project/'+instance.projectId+'/general'">{{ instance.projectId }}</router-link> {{ $store.getters.getProjectName(instance.projectId) }}
          </div>
        </div>
      </div>
    </div>
  </one-column-sidebar-left>
</template>

<script>
import InfoPanel from "../components/InfoPanel";
import OneColumnSidebarLeft from "../layouts/OneColumnSidebarLeft";
import NameDisplayEditBlock from "@/components/NameDisplayEditBlock.vue";
import ContentDescriptionBlock from "../components/ContentDescriptionBlock";
import MetaPanel from "../components/widgets/meta/MetaPanel";
import Icon from "../components/Icon";
import {mapState} from 'vuex';
import VuelidateMixin from "@/components/mixins/VuelidateMixin.js";
import MetaSetAttacher from "@/components/widgets/meta/MetaSetAttacher";
import TeamAttacher from "@/components/organizations/TeamAttacher";
import StateSetter from "@/components/StateSetter";
import {TaskManagerTaskTypes, SpecialUuids} from "@/enum";
import LogViewer from "@/components/dashboard/LogViewer";
import LogEntryProgress from "@/components/LogEntryProgress";
import DatasetRowTaskmanager from "../components/widgets/dataset/DatasetRowTaskmanager";
import Codemirror from "../components/widgets/editors/Codemirror";
import AssemblyImportConfig from "../components/assemblies/AssemblyImportConfig";
import GlbConversionTrigger from "../components/assemblies/GlbConversionTrigger";
import AssemblyCachingHandler from "@/components/AssemblyCachingHandler.vue";
export default {
  name: 'SFXEdit',
  components: {
    InfoPanel,
    MetaPanel,
    OneColumnSidebarLeft,
    NameDisplayEditBlock,
    ContentDescriptionBlock,
    Icon,
    MetaSetAttacher,
    TeamAttacher,
    StateSetter,
    LogViewer,
    LogEntryProgress,
    DatasetRowTaskmanager,
    Codemirror,
    AssemblyImportConfig,
    GlbConversionTrigger,
    AssemblyCachingHandler
  },
  mixins: [VuelidateMixin],
  data() {
    return {
      hideGlbForm: false,
      hasGlb: true,
      isUpdate: false,
      taskmanagerFields: [],
      hasWritingAccess: false,

      SpecialUuids: SpecialUuids,
      oldContent: null,
      showLoading: false,
      showProgress: false,
      content: {},

      pipelineStatus: 'finished',
      isProcessing: false,
      pipelineTask: '',

      teams: [],

      TaskManagerTaskTypes: TaskManagerTaskTypes,
      dataRow: null,

      /**
       * The root file to import
       * */
      hasRootFile: true,
      /**
       * Whether or not the assembly has already a root node
       * */
      hasRoot: false,
      hasPipelineError: false,
      instances: [],
      projectId: null
    };
  },
  computed: {
    ...mapState({
      AssemblyList: state => state.dynamicStore.AssemblyLists.AssemblyList,
    })
  },
  watch: {
    dataRow() {
      this.hasPipelineError = this.dataRow && this.dataRow.data && this.dataRow.data['6'] === 666;
    },
    AssemblyList: {
      // This will let Vue know to look inside the array
      deep: true,

      // We have to move our method to a handler field
      handler() {
        if (this.$store.getters.getAssemblyContent(this.$route.params.id)) {
          this.content = this.$store.getters.getAssemblyContent(this.$route.params.id);
          if (this.$store.getters.getAssemblyPipelineStatus(this.$route.params.id)) {
            this.pipelineStatus = this.$store.getters.getAssemblyPipelineStatus(this.$route.params.id);
            this.pipelineTask = this.$store.getters.getAssemblyPipelineTask(this.$route.params.id);
            if (this.pipelineStatus === 'processing') {
              this.isProcessing = true;
              const $this = this;
              setTimeout(() => {
                $this.showProgress = true;
              },2000)
            }

          }
        }
      }
    },
  },
  beforeMount() {
    this.loadAssetTeams();
    this.loadNewestVMLog();
    this.loadAssembly();
    this.getTaskmanagerDataRow();
  },
  methods: {
    loadUsage() {
      this.$store.dispatch('clientGetCrossProjectInstances', {
        filter: 'assetId eq ' + this.$route.params.id
      }).then(instances => {
        this.instances = instances;
      })
    },
    /**
     * Updates the current content field
     * */
    async updateContentField(content) {
      this.$store.dispatch('clientSaveAsset', {
        id: this.$route.params.id,
        content: content
      }).then(() => {
        this.loadAssembly();
      })
    },
    /**
     * Gets the data row concerning this assembly
     * */
    async getTaskmanagerDataRow() {
      await this.$store.dispatch('clientLoadDataset', {
        id: this.SpecialUuids['TASKMANAGER_DATASET'],
      }).then(item => {
        this.taskmanagerFields = item.schema.columns;
      })
      await this.pingDataRowUntilResult();
    },
    async pingDataRowUntilResult(timeout = 50000) {
      await this.$store.dispatch('clientLoadDatasetRows', {
        id: this.SpecialUuids['TASKMANAGER_DATASET'],
        filter: `key eq ${this.$route.params.id}`
      }).then(async res => {
        this.dataRow = res && res[0] ? res[0] : null;
        if (!res || !res[0]) {
          const $this = this;
          setTimeout(async () => {
            await $this.pingDataRowUntilResult();
          }, timeout);
        } /*else {
          await this.pingDataRowUntilResult(80000);
        }*/
      }).catch(e => {
        console.log(e);
      });
    },
    /**
     * Checks if a versioning-process is running (which is locking the database fields)
     * */
    isNotLocked() {
      return ![this.TaskManagerTaskTypes.VERSION_CREATE, this.TaskManagerTaskTypes.VERSION_DELETE, this.TaskManagerTaskTypes.VERSION_RESTORE].includes(this.pipelineTask)
    },
    /**
     * Loads the newest log entry
     * */
    loadNewestVMLog() {
      this.$store.dispatch('clientLoadVMLogs', {
        filter: `objectId eq ${this.$route.params.id}`,
        sort: '-createdAt',
        limit: 1
      }).then(data => {
        this.oldContent = data && data[0] ? data[0].config : {};
      })
    },
    /**
     * Loads the teams of the assembly
     * */
    loadAssetTeams() {
      this.$store.dispatch('clientLoadAssetTeams', {
        id: this.$route.params.id
      }).then(data => {
        this.teams = data;
      });
    },
    /***
     * Loads the root node of the assembly (if available)
     * */
    async loadRootNode(projectId) {
      return this.$store.dispatch('clientLoadProjectInstances', {
        id: projectId,
        filter: 'type in node model, level eq 1, parentId eq null'
      });
    },
    /**
     * Loads the assembly
     * */
    loadAssembly() {
      this.$store.dispatch('loadAssembly', {
        id: this.$route.params.id,
        include: 'tags,metaSets,teams,VMLogs',
        addPipelineState: true,
      }).then(async asset => {
        await this.checkAccess(asset);
        const projectId = asset.sourceProjectId ? asset.sourceProjectId : null;
        this.projectId = projectId;
        return this.loadRootNode(projectId);
      }).then(async res => {
        this.hasRoot = !!(res && res.length);
        if(this.hasRoot) {
          // check if root has a glb file
          await this.$store.dispatch('clientListInstanceParts', {id: res[0].id})
              .then(files => {
                if(!files || !files.filter(item => {return item.key === 'lod0.glb'}).length) {
                  this.hasGlb = false;
                }
              }).catch(e => {
                console.log(e);

              })
        }
        this.setInitialTabs();
      }).catch(e => {
        if (e.status === 401) {
          this.denyAccess();
        } else if (e.status === 404) {
          this.$router.push('/not-found')
        }
      });
    },
    /**
     * Checks whether the user has read/write access to the assembly
     * */
    async checkAccess(asset) {
      const hasAccess = await this.$store.dispatch('checkTeamOrgPermission', {
        op: 'read',
        teams: asset.teams,
        organizationId: asset.organizationId
      })
      this.hasWritingAccess = await this.$store.dispatch('checkTeamOrgPermission', {
        op: 'writeMetaSet',
        teams: asset.teams,
        organizationId: asset.organizationId
      })
      if (!hasAccess) {
        this.denyAccess();
      }
    },
    /**
     * Reroutes the user to the access denied page
     * */
    denyAccess() {
      this.$router.push('/access-denied');
    },
    /**
     * Removes the tag
     * */
    removeTag: function (tag) {
      this.$store.dispatch('removeAssemblyTag', {
        id: this.$route.params.id,
        args: [tag.id],
      });
    },
    /**
     * Adds the tag
     * */
    addTag: function (tag) {
      this.$store.dispatch('addAssemblyTag', {
        id: this.$route.params.id,
        args: [tag.id],
      });
    },
    /**
     * Whether or not to show the pipeline form
     * show it if:
     * - status is new
     * - it isn't processing
     * -
     * */
    showPipelineForm() {
      return ['new', 'newUpdate'].includes(this.pipelineStatus) && !this.isProcessing;
    }
  },
}
</script>
