<template>
  <three-columns
      :id="$route.params.id"
      back-button-tab-selector="sfxbackbutton"
      sub-menu-selector="librarypanel"
      tab-selector="assemblytab"
      :make-middle-col-larger="true"
  >
    <portal-target slot="leftContent" name="aboveTreeSection"/>
    <div slot="topContent" class="col-12 pl-4 d-flex">
      <h1>{{ $store.getters.getAssemblyDisplayName($route.params.id) }}</h1>
      <div v-if="projectId" class="ml-3 mt-2 clickable" @click="showGlobalSettings = true">
        <icon type="cog"/>
      </div>
    </div>
    <div slot="leftContent" class="row">
      <model-edit-view-popup
          v-if="isEditing && projectId && hasRoot"
          :has-write-access="hasWriteAccess"
          :instance-id="isEditing"
          :project-id="projectId"
          @abort="() => {isEditing = null;}"
          @updated="deleteCache"
      />
    </div>
    <div v-if="$route.query.nodeId" slot="leftContent" class="darker p-3 mb-1 border-radius">
      <span class="lighter">Showing node:</span> {{ $route.query.nodeId }}<br/>
      <div class="settings-button d-inline-block mt-1" @click="removeNodeId()">Reset Tree</div>
    </div>
    <div slot="leftContent">
      <instance-exporter :assembly-id="$route.params.id" />
      <div class="dflex ml-1 mt-2">
        <label :for="'multiEdit' + $vnode.key" class="container"
        >{{ $t('MultiEditing')}}
          <input
              :id="'multiEdit' + $vnode.key"
              v-model="multiEditingMode"
              type="checkbox"
              @change="selectInstances([])"
          />
          <span class="checkmark"></span>
        </label>
      </div>
    </div>
    <lazy-loading-hierchy
        v-if="projectId && !multiEditingMode"
        id="sfxEditLinkedDataHierarchy"
        :key="forceReRenderKey2"
        slot="leftContent"
        :fields="[
                    {
                        name: 'Name',
                        id: 'name',
                        type: 'string',
                        sortable: true
                    },
                    {
                        name: 'Typ',
                        id: 'type',
                        type: 'string',
                    },
                ]"
        :has-write-access="hasWriteAccess"
        :initially-selected-node="$route.query.nodeId"
        :organization-id="organizationId"
        :project-id="projectId"
        close-handler="setAssemblyTreeClosed"
        open-handler="setAssemblyTreeOpen"
        selection-handler="setAssemblyTreeSelected"
        @updated="deleteCache"
    />
    <div v-if="multiEditingMode" class="mt-2" slot="leftContent">
      <h3>Edit multiple items</h3>
      <instance-filter
          :project-id="projectId"
          @selected="selectInstances"
      />
    </div>

    <template slot="middleContent">
      <popup
          v-if="showGlobalSettings"
          @close="() => {showGlobalSettings = false}"
      >
        <assembly-global-settings
            slot="popupMainContent"
            :source-project-id="projectId"
            :organization-id="organizationId"
        />
      </popup>
      <div
          v-if="getAssemblyListOptions($route.params.id).selected_item && getAssemblyListOptions($route.params.id).selected_item.value"
          class="row">
        <div class="col-12 col-md-12 col-xl-12 mb-0 mb-xl-0 float-left float-xl-none">
          <h2 class="too-long-text-break mb-3">
            {{ getAssemblyListOptions($route.params.id).selected_item.value.displayName }}
            <info-helper
                :text="'name: ' + getAssemblyListOptions($route.params.id).selected_item.value.name + '<br />' + 'id: ' + $store.getters.getAssemblyListOptions($route.params.id, 'selected')"
                class="mb-3"
            />
          </h2>


          <h4 class="mb-1 mt-4">Materials</h4>
          <mobile-material-setter
              v-if="$store.getters.getAssemblyListOptions($route.params.id, 'selected') && $store.getters.getAssemblyListOptions($route.params.id).selected_asset"
              :default-tiling="defaultTiling"
              :asset-id="$store.getters.getAssemblyListOptions($route.params.id).selected_asset.asset.id"
              :instance-id="$store.getters.getAssemblyListOptions($route.params.id, 'selected')"
              :multi-instance-ids="selectedInstances"
              :organization-id="organizationId"
              :project-id="projectId"
              :type="$store.getters.getAssemblyListOptions($route.params.id).selected_asset.asset.type"
              class="p-2 border-radius mb-4"
              name=""
              @updated="deleteCache"
          />

          <info-panel
              info-panel-header="positioning"
              :collapsible="true"
              class="new-style bright"
              v-if="projectId && $store.getters.getAssemblyListOptions($route.params.id, 'selected') && rootId === $store.getters.getAssemblyListOptions($route.params.id, 'selected')"
          >
            <div slot="info">
              <rot-loc-scale-setter
                  class="mb-4"
                  :instance-id="$store.getters.getAssemblyListOptions($route.params.id, 'selected')"
                  :project-id="projectId"
                  @updated="deleteCache"
              />
            </div>
          </info-panel>

          <h4>All Meta Data</h4>
          <model-edit-view
              v-if="$store.getters.getAssemblyListOptions($route.params.id, 'selected')"
              :has-write-access="hasWriteAccess"
              :instance-id="$store.getters.getAssemblyListOptions($route.params.id, 'selected')"
              :project-id="projectId"
              @updateName="updateName"
              @updated="deleteCache"
          />
        </div>
      </div>
    </template>

    <div v-if="isLocked" slot="rightContent">
      <div class="form-error">
        {{ $t('assemblyIsLocked') }}
      </div>
    </div>
    <div
        v-if="hasRoot && !isExtracting && !isLocked && $store.getters.getAssemblyListOptions($route.params.id, 'selected') && projectId"
        slot="rightContent">
      <h3 class="mb-3 mb-xl-5">
        {{ $t('Preview') }}
      </h3>
      <div class="col-12 col-md-4 col-xl-12 mb-2 mt-2">
        <preview
            v-if="$store.getters.getAssemblyListOptions($route.params.id, 'fbxPreview')"
            :downloadable="true"
            :preview-id="$store.getters.getAssemblyListOptions($route.params.id, 'selected')"
            :preview-uri="$store.getters.getAssemblyListOptions($route.params.id, 'fbxPreview')"
            :removable="false"
            class="float-right float-xl-none linked-data-preview"
            source="instance"
        />
      </div>
      <download-button
          v-if="$store.getters.getAssemblyListOptions($route.params.id, 'fbxPreview')"
          :div-instead-of-button="true"
          :object-id="$store.getters.getAssemblyListOptions($route.params.id, 'selected_asset') && $store.getters.getAssemblyListOptions($route.params.id, 'selected_asset').type === 'model' ? $store.getters.getAssemblyListOptions($route.params.id, 'selected_asset').id : $store.getters.getAssemblyListOptions($route.params.id, 'selected')"
          class="col-12 xol-md-6 not-absolute clickable"
          download-message=""
          file-key=""
          loading-panel-classes="black"
      >
        <div class="settings-button darker d-inline-block pt-1 pb-1 mb-1">
          <span>{{ $t('download') }}</span>
          <icon class="ml-1" size="0.9" type="cloud"/>
        </div>
      </download-button>
    </div>
    <!--<div v-if="!isLocked && hasRoot && !isExtracting" slot="fullContentAfter" class="row">
      <div class="col-12 mt-5">
      </div>
    </div>-->
  </three-columns>
</template>

<script>
import LazyLoadingHierchy from "../components/tree/LazyLoadingHierchy";
import ThreeColumns from "../layouts/ThreeColumns";
import Preview from "../components/preview/Preview";
import Icon from "../components/Icon";
import {mapState, mapGetters} from "vuex";
import DownloadButton from "@/components/forms/DownloadButton";
import {TaskManagerTaskTypes} from "@/enum";
import ModelEditViewPopup from "../components/tree/ModelEditViewPopup";
import Popup from "../components/Popup";
import AssemblyGlobalSettings from "../components/AssemblyGlobalSettings";
import ModelEditView from "../components/tree/ModelEditView";
import MobileMaterialSetter from "../components/MobileMaterialSetter";
import {SpecialUuids} from "@/enum";
import InstanceFilter from "@/components/InstanceFilter.vue";
import InfoHelper from "@/components/InfoHelper.vue";
import RotLocScaleSetter from "@/components/RotLocScaleSetter.vue";
import InstanceExporter from "@/components/InstanceExporter.vue";
import InfoPanel from "@/components/InfoPanel.vue";
export default {
  name: "SFXMetaData",
  components: {
    RotLocScaleSetter,
    InfoHelper,
    InstanceFilter,
    Preview,
    ThreeColumns,
    LazyLoadingHierchy,
    Icon,
    DownloadButton,
    ModelEditViewPopup,
    Popup,
    AssemblyGlobalSettings,
    ModelEditView,
    MobileMaterialSetter,
    InstanceExporter,
    InfoPanel
  },
  data() {
    return {
      multiEditingMode: false,
      showGlobalSettings: false,
      offset: 0,
      listName: this.$route.params.id + 'Instances',
      tableCollapsed: false,
      forceReRenderKey: 0,
      forceReRenderKey2: 0,
      previewPath: null,
      selected: null,
      projectId: '',
      organizationId: '',
      hasRoot: false,
      rootId: null,
      isExtracting: true,
      isLocked: false,
      TaskManagerTaskTypes: TaskManagerTaskTypes,
      hasWriteAccess: false,
      isEditing: null,
      defaultTiling: [1,1],
      selectedInstances: []
    };
  },
  computed: mapState({
    ...mapGetters(['getAssemblyListOptions']),
    AssemblyListOptions: state => state.dynamicStore.AssemblyListOptions
  }),
  watch: {
    '$route.query'(val) {
      if (val.metaInfoId) {
        this.isEditing = val.metaInfoId;
      }
    }
  },
  beforeMount() {
    this.id = this.$route.params.id;
    this.$store.dispatch('clientGetPipelineStatus', {id: this.$route.params.id}).then(res => {
      this.isExtracting = false;
      this.isLocked = false;
      if (res) {
        if (res.task === 'extract') {
          this.isExtracting = true;
        }
        if ([this.TaskManagerTaskTypes.VERSION_CREATE, this.TaskManagerTaskTypes.VERSION_DELETE, this.TaskManagerTaskTypes.VERSION_RESTORE].includes(res.task)) {
          this.isLocked = true;
        }
      }
    });
    if (this.$route.query.metaInfoId) {
      this.isEditing = this.$route.query.metaInfoId;
    }
    // eslint-disable-next-line no-undef
    const redirectNodeId = $cookies.get('redirectNodeId');

    if (redirectNodeId) {
      if (this.$route.query.nodeId) {
        this.isEditing = this.$route.query.nodeId;
      }
    }

    this.loadAssembly()
        .then(async data => {
          this.projectId = data.sourceProjectId ? data.sourceProjectId : null;
          this.organizationId = data.organizationId ? data.organizationId : null;

          const {metaValues} = data;
          if(metaValues && metaValues.length) {
            const defaultTilingMeta = metaValues.find(item => {
              return item.metaFieldId === SpecialUuids.DEFAULT_TILING_META_FIELD
            });
            if(defaultTilingMeta) {
              const {value} = defaultTilingMeta;
              try {
                this.defaultTiling = JSON.parse(value);
              } catch {
                // do nothing
              }
            }
          }

          this.hasWriteAccess = await this.$store.dispatch('checkTeamOrgPermission', {
            op: 'write',
            organizationId: data.organizationId,
            teams: data.teams,
          });
          return this.loadProjectInstances(this.projectId);
        })
        .then(res => {
          this.hasRoot = !!(res && res.length);
          this.rootId = res && res.length ? res[0].id : null;
          this.setInitialTabs();
        }).catch(e => {
      if (e.status === 401) {
        this.$router.push('/access-denied');
      } else if (e.status === 404) {
        this.$router.push('/not-found')
      }
    });
  },
  methods: {
    async deleteCache() {
      await this.$store.dispatch('clientDeleteAssemblyProjectInstancesCaching', { id: this.projectId });
      this.$store.dispatch('createNotification', {
        text: 'Cache deleted',
      })
      console.log('cache deleted');
    },
    selectInstances(selectedInstances) {
      this.selectedInstances = selectedInstances;
    },
    async createInstanceFile() {
      await this.$store.dispatch('clientCreateInstanceFile', {id: this.$route.params.id})
    },
    updateName() {
      // todo
    },
    removeNodeId() {
      let query = Object.assign({}, this.$route.query);
      delete query.nodeId;
      this.$router.replace({query});
      this.forceReRenderKey2++;
    },
    async loadAssembly() {
      return this.$store.dispatch('loadAssembly', {
        id: this.id,
        include: ['teams', 'metaValues']
      });
    },
    async loadProjectInstances(projectId) {
      return this.$store.dispatch('clientLoadProjectInstances', {
        id: projectId,
        filter: 'type in node model, level eq 1, parentId eq null',
        include: ['metaValues', 'assetAndMetaSets']
      });
    },
  },
}
</script>
<style lang="scss">
.linked-data-preview {
  min-height: 50vh;
  border: 1px dashed rgba(255, 255, 255, 0.1);
}

.collapsed {
  height: 0;
  overflow: hidden;
}
</style>

<style lang="scss" scoped>
.assembly-caching-handler {
  background-color: var(--vform-editor-ui-secondary-color);
}
</style>